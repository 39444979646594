import styled from 'styled-components'
import Rectangle from '../assets/images/Rectangle.svg'

export const FlexDiv = styled.div`
    display: flex;

    border-radius: 0px; /* Rounded corners, adjust as needed */
    padding: 20px; 
    @media (max-width: 768px) {
    display: block;
    max-width: 100%;
  }
`
export const FlexDiv1 = styled.div`
    display: flex;
    @media (max-width: 1080px) {
    display: block;
    max-width: 100%;
  }
`
export const FlexDiv2 = styled.div`
    display: flex;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #ECF1F3;
    @media (max-width: 1080px) {
    flex: 1;
    flex-direction: column-reverse;
    max-width: 100%;
  }
`
export const BlueContainer = styled.div`
    margin: 0;
    @media (max-width: 768px){
      max-width: 100%; /* Make the container responsive */
      margin: 0 auto; /* Center-align the container horizontally */
      padding: 16px;
      height: auto; /* Maintain the image's aspect ratio */
      display: block;
    }
`
export const Image = styled.img`
    max-width:599px;
    @media (max-width: 768px) {
    max-width: 100%; /* Images won't overflow the viewport */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove any inline spacing */
    margin: 0 auto;
  }
`
export const CardGridd = styled.div`
  padding: 2% 8% 8%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 columns on desktop */
  gap: 20px; /* Adjust the gap between cards as needed */


    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
        grid-template-rows: repeat(1, 1fr);
    }
`;

export const CardContainer2 = styled.div`
   /* Set the width as needed */

   /* Set the height as needed */
  border-radius: 16px; /* Apply a border radius of 30px */
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:  #0492C2;
    z-index: -1; /* Place the gradient background behind the content */
  }

  /* Background image */
  background-image: url(${Rectangle}); /* Set your background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;

export const H1 = styled.h1`
  font-weight:800;
  font-size:64px; 
  line-height:77.45px; 
  min-width:30%;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size:60px;
  }
`