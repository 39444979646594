import styled from 'styled-components';
import { useState, useEffect } from 'react';

// Define the container div
export const Container = styled.div`
/* Ensure the image scales with its container */
  @media screen and (max-width: 768px) {
        text-align: center;
    }
`

// Define the image element
export const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`

// Define the top-left rectangle
export const TopLeftRectangle = styled.div`
  position: absolute;
  width: 135px;
  height: 135px;
  top: 1625px;
  left: 76px;
  border: 7px solid #0492C2;
  background-color: transparent;
  z-index: -1; /* Overlap the image by 20% */
  @media (max-width: 1024px) {
    display:none
  }
`

// Define the bottom-right rectangles
export const BottomRightRectangle = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  top: 1903px;
  left: 417px;
  border: 7px solid #0492C2;
  background-color: transparent;
  z-index: 1; /* Overlap the image */
  @media (max-width: 1024px) {
    display:none
  }
`

export const Desktop = styled.div`
    @media (max-width: 1024px) {
    display:none
  }
`
export const Desktop1 = styled.div`
    @media (max-width: 810px) {
    display:none
  }
`
export const NotDesktop = styled.div`
    display:none;
    @media (max-width: 1024px) {
    display:block;
  }
`

export const ContainerI = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const CarouselWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: ${props => props.isVisible ? 'block' : 'none'};
`;

const Carousel = styled.div`
  display: flex;
  transition: transform 0.3s ease;
`;

const CarouselImage = styled.img`
  width: 100%;
  height: auto;
  min-width: 100%;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 24px;
  color: #000;
`;

const LeftArrowButton = styled(ArrowButton)`
  left: 20px;
  z-index: 1;
`;

const RightArrowButton = styled(ArrowButton)`
  right: 20px;
  z-index: 1;
`;

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 810) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CarouselWrapper isVisible={isVisible}>
      <LeftArrowButton onClick={goToPrevSlide}>&#9664;</LeftArrowButton>
      <Carousel style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <CarouselImage
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
          />
        ))}
      </Carousel>
      <RightArrowButton onClick={goToNextSlide}>&#9654;</RightArrowButton>
    </CarouselWrapper>
  );
};

export default ImageCarousel;
