import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, Image } from '../Components/ResusableElements';
import PortsPic from '../assets/images/ports.png'
import AssetManagement from '../assets/images/assetmanagement.png'
import { Helmet } from 'react-helmet';


const Ports = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Ports</title>
        <meta name="description" content="Jamitan | Ports" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Ports</HeroH1>
        </HeroContainer3>
        <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
                <Image src={PortsPic} alt='Image10'  />
            </div>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>Large international ports are complex organizations. Port staff manage assets and facilities. They optimize transportation infrastructure and port performance—all while minimizing traffic congestion and environmental impacts. And they must coordinate all these activities to ensure unencumbered throughput that protects profit and sustainability.
For optimal performance, leaders need access to the best real-time port data and spatial intelligence to make the best decisions. Become a smart port with GIS for ports and thrive in today's dynamic port environment.
</p>
        </div>
        <div style={{textAlign:'center', paddingBottom:'20px'}}>
            <h1>Business Areas</h1>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={AssetManagement} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'2%'}}>
                    <h2>Strategic Asset Management</h2>
                    <p>Trends in global supply chains and trade are leading to ever larger vessel sizes, which require port authorities to make substantial capital investments to accommodate them. Port leaders need to comprehensively understand location and conditions to make strategic asset management decisions and cost-effective capital investments. GIS allows port managers to unite information and better understand their most valuable assets, port development, and port operations. </p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv>
            <BlueContainer>
                <div style={{padding:'2%', paddingLeft:'8%', paddingRight:'8%'}}>
                    <h2>Asset Management</h2>
                    <p>The first step of a successful asset management program is understanding the location and condition of all your assets. This helps prioritize maintenance activities and ensure performance continuity. GIS provides a comprehensive approach to maintaining your critical investments </p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv>
            <BlueContainer>
                <div style={{padding:'2%', paddingLeft:'8%', paddingRight:'8%'}}>
                    <h2>Empowered mobile workforce</h2>
                    <p>Giving your workers and field crews the right information is critical for making good decisions in the field and maintaining the currency of corporate databases. Use technology designed to empower everyone in the port—both staff and contractors—with the right information. </p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default Ports