import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Asset from '../assets/images/AssetMan.png'
import Construction from '../assets/images/Construction.png'
import Environment from '../assets/images/Environment.png'
import { Helmet } from 'react-helmet';


const Architecture = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Architecture, Engineering and Construction </title>
        <meta name="description" content="Jamitan | Architecture, Engineering, and Construction" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Architecture, Engineering and Construction</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}} className='special-text'>
            <p>Architects, engineers, builders, and owners use ArcGIS solutions to improve workflows, bring context to projects, and increase collaboration throughout project life cycles. GIS is helping architecture, engineering, and construction (AEC) firms build smart communities and assets for the future. Discover efficiencies, gain insights, and strengthen engagement with mapping applications across project teams. Add location to design to understand projects in context and deliver repeatable solutions. Location intelligence transforms the way we plan, design, build, and operate in the built and natural environment.</p>
        </div>
        <div style={{textAlign:'center'}}>
            <h1>AEC business areas</h1>
        </div>
        
        <FlexDiv>
            <BlueContainer>
                <Image src={Environment} alt='Image10' style={{width:'500px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Environmental Management</h2>
                    <p>Environmental science is a multidisciplinary field that integrates the biological, social, and physical sciences. Every environmental issue has a geographic component: coastal erosion, contamination, pollution, urbanization, desertification, habitat loss, invasive species, and deforestation, just to name a few. GIS is a powerful technology to organize, analyze, manage, and visualize geospatial data for due diligence, site assessment and remediation, environmental restoration, and ecological modeling.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Construction Management</h2>
                    <p>Our solutions take project construction management to a new level with the ability to monitor progress in maps, dashboards, and web apps fed from real-time location intelligence data sources. Construction teams make proactive, informed decisions from relevant data anytime and anywhere. GIS software allows office and mobile users to access survey and field data in real time, replacing inefficient and disconnected workflows. During construction, as the connected data environment is enriched, a digital twin is enabled. At project handover, the asset is physically and digitally delivered, ensuring a long-term relationship with the client.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Construction} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Asset} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Asset Management</h2>
                    <p>Our solutions allow facility and asset managers, executives, and operations professionals to collect data, track their assets and performance, maintain historical records, and sustain an accurate inventory. GIS provides invaluable real-time information to improve the way architecture, engineering, and construction (AEC) professionals run facilities, deliver preventive maintenance, monitor sites, and track natural environment improvement projects. Proper asset life cycle management ensures that a project fulfills its mission throughout its expected life-span. Use artificial intelligence, machine learning, and big-data methods to gain insights into assets and operations. Make better decisions effecting the life-span of assets that make up a building, or projects that make up an entire portfolio.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default Architecture