import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Land from '../assets/images/LandAdmin.png'
import Percel from '../assets/images/percelm.jpg'
import Stakeholder from '../assets/images/Stakeholder.png'
import Visualize from '../assets/images/propvalue.jpg'
import { Helmet } from 'react-helmet';


const LandAdmin = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Land Administration </title>
        <meta name="description" content="Jamitan | Land Administration" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Land Administration</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}} className='special-text'>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Image src={Land} alt='Image10'  />
            </div>
            <p>For social stability and long-term economic growth, competent management of land is essential. GIS technology aids in the management of all facets of land information, including tenure, valuation, management, and use. Governments are better able to manage land information, conduct property assessment and analysis, and communicate with the general public when all land information is contained in a comprehensive GIS-based land administration system</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Percel} alt='Image10' style={{width:'500px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Parcel Management</h2>
                    <p>The management of land parcels has always been crucial to land administration and taxation systems all throughout the world. ArcGIS Parcel Fabric (GIS parcel mapping) satisfies the needs of controlling parcel data integrity, keeping the survey record, and tracking parcel history with configurable business rules and workflow management. Your parcel data might not be as precise as you would want, but remapping would be too costly and time-consuming. Esri's ArcGIS Parcel Fabric has particular tools and workflows to assist you in enhancing your data over time as you go about your regular tasks. </p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Stakeholder Involvement</h2>
                    <p>The public is curious and inquisitive. Give accessible, reliable assessment data to the public through maps and apps so that people may obtain answers to frequent queries. Delivering self-service information, maps, and apps online decreases in-person interactions and phone calls while enhancing transparency. Authentic information is crucial for any endeavor, including home purchases and new business ventures. Esri's SaaS open data capabilities give your community the reliable information they require to take wiser decisions and achieve their objectives. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Stakeholder} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Visualize} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Visualize and Analyse Property Value</h2>
                    <p>To enhance property value assessments, use GIS to map and analyze property values. Enrich your data with location analytics, then analyze the results to see patterns and trends. You may easily identify errors and outliers, improve value models, and provide your stakeholders and taxpayers with more precise data. Improve efficiency and property analysis by quickly mapping price per square foot, comparable properties, and sales ratios. You can add more property features to your data with ArcGIS GeoEnrichment Service, enhancing data quality, analysis, and justifiable values.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default LandAdmin