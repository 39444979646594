import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import {HiExternalLink} from 'react-icons/hi'
import { Helmet } from 'react-helmet';
import onlineSolution from '../assets/images/onlineSolution.png'
import Share from '../assets/images/share.png'
import Gis from '../assets/images/gis.png'
import FieldsMap from '../assets/images/fieldMaps.png'
import GeobimPic from '../assets/images/geobim.png'


const Products = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Our Products</title>
        <meta name="description" content="Jamitan Products" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Our Products</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}}>
            <FlexDiv>
                <BlueContainer>
                    <Image src={onlineSolution} alt='Image10' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Online Solution</h2>
                        <p>ArcGIS Online is a software-as-a-service (SaaS) offering that organizations can use to create, manage, and share geospatial content in the cloud.</p>
                        <span style={{color:'#0492C2'}}><a href='/online-solution' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                    
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Desktop Software</h2>
                        <p>Share your work with anyone, anywhere. Work with your colleagues on analysis projects, share analysis workflows, communicate results, and enable others to perform the same analysis tasks to make informed decisions.</p>
                        <span style={{color:'#0492C2'}}><a href='/desktop-software' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Share} alt='Image11' />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Gis} alt='Image12'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>GIS Enterprise</h2>
                        <p>Build your foundation using ArcGIS Enterprise, the all-inclusive system. Create maps, analyze geospatial data, and communicate insights to address issues using ArcGIS Enterprise, the all-inclusive software system for all your geospatial needs. Esri's industry-leading GIS mapping software will support your work in the cloud or behind your firewall and produce efficient results.</p>
                        <span style={{color:'#0492C2'}}><a href='/gis-enterprise' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Field Maps</h2>
                        <p>The influence of geography enhances field operations' coordination and effectiveness. Utilize field apps to lessen or perhaps eliminate the need for paper. Through specifically designed data gathering apps, increase the field assets' data accuracy. To minimize errors, increase efficiency, and save money, make sure that office and field personnel use the same reliable data.</p>
                        <span style={{color:'#0492C2'}}><a href='/field-maps' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={FieldsMap} alt='Image11' />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={GeobimPic} alt='Image12'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>GeoBIM</h2>
                        <p>Using data from various systems in a geographic context, ArcGIS GeoBIM provides teams with an innovative, user-friendly web-based experience to explore and engage on Building Information Modeling (BIM) projects and concerns. Teams working in Architecture, Engineering, Construction (AEC), and operations may quickly collaborate with linked data and documentation in customizable web apps.</p>
                        <span style={{color:'#0492C2'}}><a href='/geobim' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
        </div>
        <Footer />
    </div>
  )
}

export default Products