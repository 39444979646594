import styled from 'styled-components'
import global from '../assets/images/global-network.svg'


export const CardGrid = styled.div`
  padding: 2% 8% 8%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 20px; /* Adjust the gap between cards as needed */

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
    grid-template-rows: repeat(2, 1fr); /* 4 rows on mobile */
  }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
        grid-template-rows: repeat(1, 1fr);
    }
`;

export const Card = styled.div`
  background-color: #ECF1F3;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  /* Add other card styles as needed */
`;

export const CardContainer = styled.div`
   /* Set the width as needed */
  margin-left: 8%;
  margin-right: 8%;
   /* Set the height as needed */
  border-radius: 30px; /* Apply a border radius of 30px */
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, #DCECF3, #C0D6DF);
    z-index: -1; /* Place the gradient background behind the content */
  }

  /* Background image */
  background-image: url(${global}); /* Set your background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;

export const TextDiv = styled.div`
    @media screen and (max-width: 768px) {
       
    }
`
export const TextDiv1 = styled.div`
   
    padding: 4%;
    @media screen and (max-width: 768px) {
       
    }
`

export const Div = styled.div`
  display: flex;

    @media screen and (max-width: 768px) {
       display: block;
    }
`;

export const ContactFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormField = styled.input`
  top: 65px;
  left: 587px;
  padding: 22px 30px;
  border-radius: 16px;
  border: none;
  margin-bottom: 20px;
  @media (min-width: 769px) {
    margin-left: '10px'
  }
`;

export const MessageTextArea = styled.textarea`
  height: 167px;
  top: 255px;
  left: 587px;
  padding: 22px 30px;
  border-radius: 16px;
  margin-bottom: 20px;
  border: none;
`;

export const SubmitButton = styled.button`
  width: 158px;
  height: 54px;
  float: right; /* Float submit button to the right */
  padding: 16px 64px;
  border-radius: 14px;
  background-color: #0485C2;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #157393;
  }
  @media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    margin: 0 auto; /* Center the button on smaller screens */
  }
`;

export const Div1 = styled.div`
    display: flex;
    @media (max-width: 768px) {
        display: contents;
        gap: 0;

  }

`
export const Container = styled.div`
    height: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    overflow: scroll;
    left: 370px;

    @media screen and (max-width: 960px) {
        left: 0;
    }
`
export const Form2 = styled.div`
    background: transparent;
    max-width: 500px;
    height: auto;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 32px;
    border-radius: 6px;
`
export const FormLabel = styled.label`
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #1E1E1E;
    margin-left: 5px;
`
export const FormInput = styled.input`
    padding: 16px 16px;
    border: 1px solid #0492C2;
    border-radius: 12px;
    background-color: transparent;
`

export const Div2 = styled.div`
  display: grid;

`
export const Div3 = styled.div`
  @media (max-width: 768px) {
  }

`