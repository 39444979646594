import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Share from '../assets/images/share.png'
import Integrate from '../assets/images/integrate.png'
import Storytelling from '../assets/images/storytelling.png'
import { Helmet } from 'react-helmet';


const DesktopSoftware = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Desktop Software</title>
        <meta name="description" content="Jamitan Mapping Tech" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Desktop Software</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}} className='special-text'>
            <p>Share your work with anyone, anywhere. Work with your colleagues on analysis projects, share analysis workflows, communicate results, and enable others to perform the same analysis tasks to make informed decisions</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Share} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Share</h2>
                    <p>Collaborate with your team, organization, and others outside of your organization while safely sharing analytical models, data, and tools. Give professionals the freedom to communicate their analysis processes to others. Include analytical tools in the appealing apps and user interfaces you design for end users. </p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Integrate Analytics Across Your Organization</h2>
                    <p>Make your models accessible and enterprise ready to integrate analytics into your organization's decision-making process. Use your desktop, the cloud, or a network firewall to deploy your analysis as ready-to-use tools or notebooks. Run your analytical models on a regular basis so that your company always uses the most recent data.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Integrate} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Storytelling} alt='Image12' style={{width:'720px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Storytelling</h2>
                    <p>While stories inspire action, algorithms provide answers. Make sure the decision-makers at your organization benefit from your analysis. Using the integrated storytelling features that include text, photos, and videos, you can transform complex analysis into captivating narrative. Share your stories with your clients, your coworkers or even the public.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default DesktopSoftware