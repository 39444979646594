import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Gis from '../assets/images/gis.png'
import CustomSec from '../assets/images/customisesec.png'
import Data from '../assets/images/data.png'
import Analyse from '../assets/images/analyse.png'
import { Helmet } from 'react-helmet';


const GisEnterprise = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>GIS Enterprise</title>
        <meta name="description" content="Jamitan Mapping Tech" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>GIS Enterprise</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}} className='special-text'>
        <div style={{display:'flex', justifyContent:'center'}}>
                <Image src={Gis} alt='Image10'  />
            </div>
            <p>Build your foundation using ArcGIS Enterprise, the all-inclusive system. Create maps, analyze geospatial data, and communicate insights to address issues using ArcGIS Enterprise, the all-inclusive software system for all your geospatial needs. Esri's industry-leading GIS mapping software will support your work in the cloud or behind your firewall and produce efficient results.
            </p>
        </div>
            
        <FlexDiv style={{marginTop:'10px'}}>
            <BlueContainer>
                <Image src={CustomSec} alt='Image10' style={{width:'500px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Customize Your Security</h2>
                    <p>You can rely on the safe, business-ready software provided by ArcGIS Enterprise. Integrate with current identity stores to provide your team, organization or clients a seamless experience. To support your mission-critical workflows, configure high availability and disaster recovery solutions. In addition, you have the ability to uniquely tailor your deployment when you create your geospatial foundation with ArcGIS Enterprise. </p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Manage Your Data</h2>
                    <p>You can employ ready-to-use data storage alternatives with ArcGIS Enterprise that don't require any additional administration or money -take full advantage of Esri's wide collection of curated GIS data for a better outlook. Additionally, you can provide quick and secure access to user-managed data stores and utilize that data to fuel the maps and applications created by your organization.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Data} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Analyse} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Analyse and Discover</h2>
                    <p>Use ArcGIS Enterprise features to conduct analysis within your web browser to find patterns, trends, and anomalies in your data. With ArcGIS Enterprise, unlock the potential of your data, whether it be raster, real-time, or big data. On ArcGIS Enterprise, there are countless tools available for data analysis. Choose from a variety of analysis tools that may be accessible through a browser, on your desktop, or even through applications, whether you have tabular, vector, or raster data.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default GisEnterprise