import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import RailPic from '../assets/images/rail.png'
import Asset from '../assets/images/rail-asset.png'
import Operations from '../assets/images/rail-operations.png'
import Infra from '../assets/images/rail-infra.png'
import Safety from '../assets/images/rail-safety.png'
import Performance from '../assets/images/rail-performance.png'
import { Helmet } from 'react-helmet';


const Rail = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Rail</title>
        <meta name="description" content="Jamitan | Rail" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Rail</HeroH1>
        </HeroContainer3>
        <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
                <Image src={RailPic} alt='Image10'  />
            </div>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>Whether you are moving freight across a state or across countries, rail remains one of the most cost-effective modes of transportation. For companies supporting global supply chains or simply bringing crops to market, rail plays an ever-critical role in our modern transportation systems.
            Rail can reach its full potential with GIS technology and information systems that improve performance through real-time decision-support systems. This moment calls for a comprehensive digital transformation and an ability to leverage location intelligence. Esri can help power that transformation.
            </p>
        </div>
        <div style={{textAlign:'center', paddingBottom:'20px'}}>
            <h1>Business Areas</h1>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Asset} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Asset Management and Maintenance</h2>
                    <p>A prerequisite for good rail asset management is a comprehensive asset inventory. Use GIS to identify the precise locations of all of your critical rail assets—from signaling and communications equipment to track and other wayside assets. With a rich set of tools and applications, GIS helps you maintain that inventory over time, so your data is always current. Good rail asset management practice requires understanding asset degradation cycles and the performance of assets over time. GIS, combined with a comprehensive asset management solution, helps you maintain a state of good repair and stay ahead of maintenance activities. Move from reactive maintenance to predictive maintenance and avoid costly asset failures. </p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Operations</h2>
                    <p>ArcGIS can seamlessly integrate with your existing real-time business systems. Bringing that data into a spatial environment allows you to better control your real-time rail operations and analyze your performance. Whether monitoring your assets or rolling stock in real time, a centralized spatial command center lets you gain greater control over your operations. Unite key information to deliver superior service. Having an overall system view of your rail operations is critical to effective performance. ArcGIS allows you to unite data from many sources into a single operational view, so you can take greater control of your operations. Our GIS technology allows you to unite data from different business systems to help you monitor and continuously improve performance. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Operations} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Infra} alt='Image12' style={{width:'720px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Infrastructure and Property Management</h2>
                    <p> Rail agencies have to properly manage land, rights-of-way, and facilities to ensure profitability and safe operations to maintain rail infrastructure. Having a comprehensive understanding of the properties and facilities you own is critical to successful management. It's essential to manage station facilities and extensive properties like a business, ensuring the maximum and best use of space while still providing optimum performance.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Safety and Security</h2>
                    <p>Providing a safe and secure system is critical to your continued success. GIS delivers a comprehensive real-time view of rail operations for enhanced rail safety and security—both in the station and on the train—for greater situational awareness. Get solutions designed to help you respond more effectively in the field and achieve better coordination among your security personnel. The core challenge for many security professionals is integrating disparate technologies into a framework that delivers broad situational intelligence. GIS helps address this problem. Using GIS to integrate all sensor and surveillance feeds gives rail staff access to all their control systems for a comprehensive rail security operational view. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Safety} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Performance} alt='Image12' style={{width:'720px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Performance and Monitoring</h2>
                    <p>Successful and growing rail agencies have a strong focus on customer satisfaction and monitor rail performance improvement. With location analytics, you can unite information from across your organization to analyze overall performance in new ways. Use executive dashboards and other analytical tools to focus on key performance metrics and communicate your success to the public. Get the ability to visualize and monitor almost every rail performance metric. Unite data from across your divisions to better understand your performance at a glance and quickly see where you can improve.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default Rail