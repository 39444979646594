import React, {useState} from 'react'
import { Card, CardContainer, CardGrid, ImageContainers, Images, TextDiv, TextDiv1 } from './ProductsElements'
import cloud from '../assets/images/cloud.svg'
import desktop from '../assets/images/desktop.svg'
import enterprise from '../assets/images/ent.svg'
import compass from '../assets/images/compass.svg'
import building from '../assets/images/building.svg'
import dev from '../assets/images/dev.svg'
import { Button } from './ButtonElements'
import {HiExternalLink} from 'react-icons/hi'
import Train from '../assets/images/train.png'
import Intel from '../assets/images/intel.png'
import ImageryPic from '../assets/images/imagery.png'
import Rta from '../assets/images/rta.png'
import Image11 from '../assets/images/image11.svg'

 
const OurProducts = () => {

    const [isOpen, setIsOpen] = useState(false); 

  return (
    <div>
        <div style={{textAlign:'center', paddingTop:'4%', paddingBottom:'2%', color:'#000', fontWeight:'700', fontSize:'40px', lineHeight:'48.41px'}}>
            Our Products
        </div>
        <CardGrid>
            <Card to='/online-solution'>
                <img src={cloud} alt='cloud' style={{margin:'10px'}} />
                <div style={{fontWeight:'600', fontSize:'20px'}}>Online Solution</div>
                <p>
                ArcGIS Online is a software-as-a-service (SaaS) offering that organizations can use to create, manage, and share geospatial content in the cloud.
                </p>
            </Card>
            <Card to='/desktop-software'>
                <img src={desktop} alt='desktop' style={{margin:'10px'}} />
                <div style={{fontWeight:'600', fontSize:'20px'}}>Desktop Software</div>
                <p>Share your work with anyone, anywhere. Work with your colleagues on projects, share analysis workflows, communicate results, and enhance collaboration.</p>
            </Card>
            <Card>
                <img src={enterprise} alt='enterprise' style={{margin:'10px'}} />
                <div style={{fontWeight:'600', fontSize:'20px'}}>GIS Enterprise</div>
                <p>
                    Build your foundation using ArcGIS Enterprise, the all-inclusive system. Create maps, analyze geospatial data, and communicate insights using ArcGIS Enterprise.
                </p>
            </Card>
            <Card to='/field-maps'>
                <img src={compass} alt='compass' style={{margin:'10px'}}/> 
                <div style={{fontWeight:'600', fontSize:'20px'}}>Field Maps</div>
                <p>Utilize field apps to lessen or eliminate the need for paper. Through specifically designed data gathering apps, increase the field asset’s data accuracy.</p>
            </Card>
            <Card to='/geobim'>
                <img src={building} alt="building" style={{margin:'10px'}} />
                <div style={{fontWeight:'600', fontSize:'20px'}}>GeoBIM</div>
                <p>ArcGIS GeoBIM provides teams with an innovative, user-friendly web-based experience to explore and engage on Building Information Modeling (BIM) projects and concerns.</p>
            </Card>
            <Card>
                <img src={dev} alt='developer' style={{margin:'10px'}}/>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Developer Platform</div>
                <p>ArcGIS Online is a software-as-a-service (SaaS) offering that organizations can use to create, manage, and share geospatial content in the cloud.</p>
            </Card>
        </CardGrid>
        <CardContainer>
            <div style={{display:'flex'}}>
                <TextDiv1>
                    <div style={{textAlign:'start',  color:'#fff', fontWeight:'700', fontSize:'40px', lineHeight:'48.41px'}}>
                        Our Services
                    </div>
                    <p style={{textAlign:'start',fontWeight:'400',lineHeight:' 19.36px', fontSize:'16px',color:'#fff'}}>ArcGIS Online is a software-as-a-service (SaaS) offering that organizations can use to create, manage, and share geospatial content in the cloud.</p>
                    <Button to='/services' style={{borderRadius:'14px', padding: '16px, 64px, 16px, 64px', backgroundColor:'white', color:'black', maxWidth:'221px'}}>
                        <span  style={{fontWeight: '600',lineHeight:'19.36px', fontSize:'16px'}}>Explore</span> <HiExternalLink style={{fontSize:'20px', marginLeft:'10px'}} />
                    </Button>
                </TextDiv1>
                <TextDiv>
                    <ImageContainers>
                        <Images src={Intel} />
                    </ImageContainers>
                    <div style={{height: '314px'}}>
                    <ImageContainers style={{marginBottom: '10px'}}>
                        <Images src={Train} />
                    </ImageContainers>
                    <ImageContainers>
                        <Images src={ImageryPic} />
                    </ImageContainers>
                    </div>
                    <div style={{height: '314px'}}>
                    <ImageContainers style={{top: '-40%'}}>
                        <Images src={Image11} />
                    </ImageContainers>
                    <ImageContainers style={{top: '-40%', marginTop:'10px'}}>
                        <Images src={Rta} />
                    </ImageContainers>
                    </div>
                </TextDiv>
            </div>
        </CardContainer>
    </div>
  )
}

export default OurProducts