import React, {useState, useEffect} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroContainer4, HeroH1 } from '../Components/HeroElements';
import about from '../assets/images/about.svg'
import Adigun from '../assets/images/adigun.svg'
import Akitan from '../assets/images/akintan.svg'
import Esri from '../assets/images/esri.svg'
import Khatib from '../assets/images/khatib.svg'
import AWS from '../assets/images/aws.svg'
import Maxar from '../assets/images/maxar.svg'
import Comp from '../assets/images/comp.png'
import Private from '../assets/images/private.png'
import Scalable from '../assets/images/scalable.png'
import Frame32 from '../assets/images/Frame32.svg'
import { CardContainer2, CardGridd, FlexDiv, FlexDiv1, H1 } from '../Components/ResusableElements';
import { Card,CardGrid,CardGridGallery, CardGridValues } from '../Components/ProductsElements';
import ImageCarousel, { BottomRightRectangle, Container, ContainerI, Desktop, Desktop1, NotDesktop, TopLeftRectangle } from '../Components/AboutElements';
import {HiExternalLink} from 'react-icons/hi'
import { Helmet } from 'react-helmet';
import BASE_URL from '../apiConfig'
import Token from '../api'
import axios from 'axios';
import styled from 'styled-components';

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    /* Large desktop screens (3x2) */
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    /* iPads and low-res laptops (2x3) */
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    /* Phones (1x6) */
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  img {
    max-width: 100%;
    height: auto;
  }
`;

const ImageCard = ({ imgSrc }) => {
    return (
      <Card2>
        <img src={imgSrc} alt="Card Image" />
      </Card2>
    );
  };

const About = () => {
    
    const [isOpen, setIsOpen] = useState(false); 
    const [aboutUsData, setAboutUsData] = useState();
  
    useEffect(() => {
      // Create a config object with the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
  
      // Make an Axios GET request to the API with the Authorization header
      axios.get(`${BASE_URL}/about-us`, config)
        .then(response => {
          // If the API returns a response, set the data in the state
          setAboutUsData(response.data[0]);
        })
        .catch(error => {
          // If there's an error (e.g., no response from the API), you can handle it here
          console.error('Error fetching data:', error);
          // Display the default content in case of an error
        });
    }, []);
    const toggle = () => {
      setIsOpen(!isOpen);
    };
    const images = [
        aboutUsData?.images[0]?.full_url !== undefined || ''   ? aboutUsData?.images[0]?.full_url : Frame32,
        aboutUsData?.images[1]?.full_url !== undefined || ''   ? aboutUsData?.images[1]?.full_url : Frame32,
        aboutUsData?.images[2]?.full_url !== undefined || ''   ? aboutUsData?.images[2]?.full_url : Frame32,
        aboutUsData?.images[3]?.full_url !== undefined || ''   ? aboutUsData?.images[3]?.full_url : Frame32,
        aboutUsData?.images[4]?.full_url !== undefined || ''   ? aboutUsData?.images[4]?.full_url : Frame32,
        aboutUsData?.images[5]?.full_url !== undefined || ''   ? aboutUsData?.images[5]?.full_url : Frame32,
      ];
      useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
        <Helmet>
        <title>About Us</title>
        <meta name="description" content="About Jamitan Tech" />
      </Helmet>
        <div style={{overflow:'hidden'}}>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
            <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>About Us</HeroH1>
        </HeroContainer3>
        <div style={{padding:'8%'}}>
            <FlexDiv1>
                <H1>Who we are</H1>
                <div style={{padding:'0 8%',fontWeight:'400', lineHeight:'30.6px'}}>
                    {aboutUsData ? (<div dangerouslySetInnerHTML={{ __html:aboutUsData.whoarewe }} />) : <p><span style={{color: '#0492C2', fontWeight:'700'}}>JAMITAN Tech</span> is a geospatial company registered in Nigeria, and made up of professionals experienced in the use of geospatial applications in various industries. As full-service Geographic Information System (GIS) providers, we support the implementation of GIS technology on desktops, servers, and online mobile devices. Our solution, ArcGIS Enterprise Software, is flexible, customizable, and easy to use.
We deliver broadband communications and advance space operations with capabilities in Space Infrastructure and Earth Intelligence. We provide Unmanned Aerial Vehicles (UAV) services, with the capability to conduct mid-range inspections, long-range surveillance operations and accurate aerial mapping services.
We provide the use of geographic information system (GIS) software, location intelligence, and mapping. Since 2018, we have supported customers with geographic science and geospatial analytics, what we call The Science of Where. We take a geographic approach to problem-solving, brought to life by modern GIS technology. We are committed to using science and technology to build a sustainable world.</p>}
                </div>
            </FlexDiv1>
        </div>
        <div style={{textAlign:'center', paddingBottom:'2%', color:'#0492C2', fontWeight:'700', fontSize:'40px', lineHeight:'48.41px'}}>
                Core Values
            </div>
        <CardGridValues style={{padding:'0'}}>
            <Card style={{fontWeight:'600', backgroundColor:'rgba(4, 146, 194, 0.1)',borderRadius:'0'}}>
                Innovation
            </Card>
            <Card style={{fontWeight:'600', backgroundColor:'rgba(4, 146, 194, 0.1)',borderRadius:'0'}}>
                Collaboration
            </Card>
            <Card style={{fontWeight:'600', backgroundColor:'rgba(4, 146, 194, 0.1)',borderRadius:'0'}}>
                Excellence
            </Card>
        </CardGridValues>
        <div style={{marginTop:'4%'}}>
            <CardGridd>
                <CardContainer2>
                    <div style={{textAlign:'start',  color:'#fff', fontWeight:'700', fontSize:'40px', lineHeight:'48.41px'}}>
                        Vision
                    </div>
                    <p style={{textAlign:'start',fontWeight:'400',lineHeight:' 19.36px', fontSize:'16px',color:'#fff'}}>To help build and develop a more sustainable Nigeria through the adoption of geospatial science and technology across all sectors</p>
                </CardContainer2>
                <CardContainer2>
                    <div style={{textAlign:'start',  color:'#fff', fontWeight:'700', fontSize:'40px', lineHeight:'48.41px'}}>
                        Mission
                    </div>
                    <p style={{textAlign:'start',fontWeight:'400',lineHeight:' 19.36px', fontSize:'16px',color:'#fff'}}>To provide and apply geospatial science and location intelligence toward analytical problem-solving</p>
                </CardContainer2>
            </CardGridd>
        </div>
        <Desktop>
            <FlexDiv1 style={{padding:'8%', gap:'30px'}}>
                <Container>
                        <img src={Adigun} alt='Mr Sammy Oreoluwa Adigun' style={{borderRadius:'6%'}} />
                </Container>
                    <div style={{padding:'0 8%'}}>
                        <h3 style={{fontWeight: '700', fontSize:'32px'}}>Chairman</h3>
                        <span style={{color:'#0492C2', fontWeight:'700', fontSize: '20px', textTransform:'uppercase'}}>{aboutUsData ? aboutUsData.chairmansname : 'SAMMY OREOLUWA ADIGUN'}</span>
                        <p style={{ fontWeight:'400', lineHeight:'30.6px'}}>{aboutUsData ? aboutUsData.chairmanbio : "Mr. Sammy Adigun is a Cost Engineer and an Entrepreneur, with about 20 years of experience in Project planning, Execution, Cost analysis, Value Engineering, and Construction economics. He draws on a strong background in senior management and has a track record of directing strategic projects, creating high-performing teams, and reaching challenging goals."}</p>
                    </div>
            </FlexDiv1>
            <FlexDiv1 style={{padding:'8%', gap:'30px'}}>
                    <div style={{padding:'0 8%'}}>
                        <h3 style={{fontWeight: '700', fontSize:'32px'}}>C.E.O</h3>
                        <span style={{color:'#0492C2', fontWeight:'700', fontSize: '20px', textTransform:'uppercase'}}>{aboutUsData ? aboutUsData.ceosname : 'OLAWANLE AKINTAN'}</span>
                        <p style={{ fontWeight:'400', lineHeight:'30.6px'}}>{aboutUsData ? aboutUsData.ceobio : 'Mr. Olawanle Akintan is a certified ArcGIS expert, with years of experience in the use of Geographical Information Systems (GIS) for data management and visualization over desktop and mobile devices. He is well-versed in the use of software like MapInfo, Google Earth Pro, Google Cloud Mapping Services, and ArcGIS over a variety of layers.'}</p>
                    </div>
                    <Container>
                        <img src={Akitan} alt='Mr Sammy Oreoluwa Adigun' style={{borderRadius:'6%'}}/>
                    </Container>
            </FlexDiv1>
        </Desktop>
        <NotDesktop>
            <div style={{padding:'8%', gap:'30px'}}>
                <ContainerI>
                    <img src={Adigun} alt='Mr Sammy Oreoluwa Adigun' style={{borderRadius:'6%'}} />
                </ContainerI>
                    <div style={{padding:'0 8%'}}>
                        <h3 style={{fontWeight: '700', fontSize:'32px'}}>Chairman</h3>
                        <span style={{color:'#0492C2', fontWeight:'700', fontSize: '20px', textTransform:'uppercase'}}>{aboutUsData ? aboutUsData.chairmansname : 'SAMMY OREOLUWA ADIGUN'}</span>
                        <p style={{ fontWeight:'400', lineHeight:'30.6px'}}>{aboutUsData ? aboutUsData.chairmanbio : "Mr. Sammy Adigun is a Cost Engineer and an Entrepreneur, with about 20 years of experience in Project planning, Execution, Cost analysis, Value Engineering, and Construction economics. He draws on a strong background in senior management and has a track record of directing strategic projects, creating high-performing teams, and reaching challenging goals."}</p>
                    </div>
            </div>
            <div style={{padding:'8%', gap:'30px'}}>
                    <ContainerI>
                        <img src={Akitan} alt='Mr Sammy Oreoluwa Adigun' style={{borderRadius:'6%'}}/>
                    </ContainerI>
                    <div style={{padding:'0 8%'}}>
                        <h3 style={{fontWeight: '700', fontSize:'32px'}}>C.E.O</h3>
                        <span style={{color:'#0492C2', fontWeight:'700', fontSize: '20px', textTransform:'uppercase'}}>{aboutUsData ? aboutUsData.ceosname : 'OLAWANLE AKINTAN'}</span>
                        <p style={{ fontWeight:'400', lineHeight:'30.6px'}}>{aboutUsData ? aboutUsData.ceobio : 'Mr. Olawanle Akintan is a certified ArcGIS expert, with years of experience in the use of Geographical Information Systems (GIS) for data management and visualization over desktop and mobile devices. He is well-versed in the use of software like MapInfo, Google Earth Pro, Google Cloud Mapping Services, and ArcGIS over a variety of layers.'}</p>
                    </div>
            </div>
        </NotDesktop>
        <div style={{marginTop:'4%'}}>
            <CardGridd>
                <CardGridd style={{background:'#E4EFF3', display:'block', borderRadius:'16px'}}>
                    <div style={{fontWeight:'700', lineHeight:'48.41px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{fontSize:'24px'}}>ESRI</span>
                        <img src={Esri} alt='Esri' style={{float:'right'}} />
                    </div>
                    <p style={{textAlign:'start',fontWeight:'400',lineHeight:' 19.36px', fontSize:'16px'}}>Esri offers Mapping, Field Operations, Spatial Analysis & Data Science, Imagery and Remote Sensing, Indoor GIS, Real-Time Visualization & Analystics, 3D Visualization, Data Management and Developer APIs</p>
                    <span style={{color:'#0492C2'}}><a href='https://esri.com' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2'}}>Visit website<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>                </CardGridd>
                <CardGridd style={{background:'#E4EFF3', display:'block', borderRadius:'16px'}}>
                    <div style={{fontWeight:'700',  lineHeight:'48.41px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{fontSize:'24px'}}>Khatib & Alami (K&A)</span>
                        <img src={Khatib} alt='Khatib' style={{float:'right'}} />
                    </div>
                    <p style={{textAlign:'start',fontWeight:'400',lineHeight:' 19.36px', fontSize:'16px'}}>K&A offers comprehensive implementation of geospatial & system integration solutions. Enterprise GIS, GIS strategy and planning, system architecture design, business case development and many more</p>
                    <span style={{color:'#0492C2'}}><a href='https://khatibalami.com' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2'}}>Visit website<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                </CardGridd>
                <CardGridd style={{background:'#E4EFF3', display:'block', borderRadius:'16px'}}>
                    <div style={{fontWeight:'700',  lineHeight:'48.41px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{fontSize:'24px'}}>Amazon Web Services (AWS)</span>
                        <img src={AWS} alt='AWS' style={{float:'right'}} />
                    </div>
                    <p style={{textAlign:'start',fontWeight:'400',lineHeight:' 19.36px', fontSize:'16px'}}>AWS offers various services including: Data Archiving, Backup & Storage, Blckchain Services, Cloud Migration, Cloud Operations, Content Delivery, Database Migration, Data Lakes & Analytics and many more.</p>
                    <span style={{color:'#0492C2'}}><a href='https://aws.amazon.com' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2'}}>Visit website<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                </CardGridd>
                <CardGridd style={{background:'#E4EFF3', display:'block', borderRadius:'16px'}}>
                    <div style={{fontWeight:'700',  lineHeight:'48.41px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <span style={{fontSize:'24px'}}>Maxar Technologies</span>
                        <img src={Maxar} alt='Khatib' style={{float:'right'}} />
                    </div>
                    <p style={{textAlign:'start',fontWeight:'400',lineHeight:' 19.36px', fontSize:'16px'}}>Maxar offers geospatial services, satellite imagery and expert intelligence, robotics and servicing and also specializes in manufacturing, surveillance and communication, radar, and satellite products.</p>
                    <span style={{color:'#0492C2'}}><a href='https://maxar.com' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2'}}>Visit website<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                </CardGridd>
            </CardGridd>
        </div>
        <div style={{padding:'8%'}}>
            <FlexDiv1>
                <H1>Our Technology</H1>
                <div style={{padding:'0 8%',fontWeight:'400', lineHeight:'30.6px'}}>
                    {aboutUsData ? (<div dangerouslySetInnerHTML={{ __html: aboutUsData.ourtechnology}} />) : <p>GIS uses cutting-edge geospatial data collection techniques like Global Positioning System (GPS), Light Detection and Ranging (LiDAR), aerial photography, and satellite images. These data sources offer precise and thorough information about the Earth's surface, including information on the terrain, structures, plant life, and usage of land. <br /><br/> It creates reliable systems for integrating, organizing, and storing data. GIS companies use geospatial software and databases to successfully manage massive amounts of data. Additionally, to guarantee the precision and dependability of geospatial information, these businesses employ strategies including data standardization, metadata generation, and data quality management.<br/><br/>GIS makes use of sophisticated analysis techniques to get valuable conclusions from geospatial data. Tasks like proximity analysis, overlay analysis, terrain analysis, and network analysis are all carried out using spatial analysis techniques. These analyses aid in comprehending the patterns, connections, and trends present in geographical datasets. Visualization & Mapping, a key component of GIS technology, focuses on producing visually appealing and educational maps and representations. Interactive visualization technologies and cartographic techniques are used to portray geospatial data in a clear and user-friendly manner.</p>}
                </div>
            </FlexDiv1>
        </div>
        <div style={{padding:'8%', paddingTop:'2%'}}>
            <FlexDiv1>
                <H1>Our Solution</H1>
                <div style={{padding:'0 8%',fontWeight:'400', lineHeight:'30.6px'}}>
                    {aboutUsData ? (<div dangerouslySetInnerHTML={{ __html: aboutUsData.oursolution }} />) : <p>Esri’s ArcGIS is a complete GIS system that offers flexible licensing and deployment, a collection of ready-to-use apps, reliable data, developer tools, a thriving user community, and extensive technical training and support choices to fully equip your organization.  <br /><br /> ArcGIS provides tools for location-centric data collection, viewing, editing, management, analysis, and sharing. For research and insight, it provides access to hundreds of carefully curated datasets and maps that can be explored and used.<br/><br/>ArcGIS may be used to generate maps, apps, dashboards, 3D sceneries and models, and data science notebooks on PCs, mobile devices, and the cloud.</p>}
                </div>
            </FlexDiv1>
        </div>
        <CardGrid>
            <Card>
                <div style={{display:'grid', justifyContent:'center'}}><img src={Scalable} alt='cloud' style={{margin:'10px', width:'200px'}} /></div>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Scalable</div>
                <p>
                    ArcGIS scales to meet your demand as usage requirements grow, assisting enterprises as data volume, complexity, and velocity increase. 
                </p>
            </Card>
            <Card>
            <div style={{display:'grid', justifyContent:'center'}}><img src={Private} alt='desktop' style={{margin:'10px', width:'200px'}} /></div>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Private and secure</div>
                <p>Even the businesses and industries with the strictest regulations trust Esri because it integrates security, privacy, and compliance into the heart of ArcGIS.</p>
            </Card>
            <Card>
            <div style={{display:'grid', justifyContent:'center'}}><img src={Comp} alt='enterprise' style={{margin:'10px',width:'200px'}} /></div>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Comprehensive and versatile</div>
                <p>
                ArcGIS interfaces with significant company systems and processes. It functions within your system or through cloud service providers like AWS.
                </p>
            </Card>
        </CardGrid>
        <div style={{textAlign:'center', paddingBottom:'2%', color:'#1E1E1E', fontWeight:'700', fontSize:'40px', lineHeight:'48.41px'}}>
            Gallery
        </div>
        <Desktop1 style={{padding:'20px'}}>
        <CardContainer>
      {images?.map((item, index) => (
        <ImageCard key={index} imgSrc={item} />
      ))}
    </CardContainer>
        </Desktop1>
            <ImageCarousel images={images} />
        <Footer />
        </div>
    </>
    
  )
}

export default About