import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Opps from '../assets/images/ops.png'
import ThreeDVisual from '../assets/images/3dvisualisation.png'
import BigData from '../assets/images/bigtime.jpg'
import Rta from '../assets/images/real.jpg'
import Intel from '../assets/images/noise.jpg'
import { Helmet } from 'react-helmet';


const Visualize = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Visualization & Analytics</title>
        <meta name="description" content="Jamitan Mapping Tech" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Visualization & Analytics</HeroH1>
        </HeroContainer3>
        <FlexDiv style={{marginTop:'10px'}}>
            <BlueContainer>
                <Image src={Opps} alt='Image10' style={{width:'500px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Operational dashboards</h2>
                    <p>Charts, gauges, maps, and other visual components that display the status and performance of crucial assets and events in real-time can be used to visualize your Internet of Things data. The ideal tool to give a complete operating picture of your large data in real time is Operations Dashboard for ArcGIS.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Turn noise into intelligence</h2>
                    <p>Visualize and analyze massive volumes of data as well as trends, correlations, anomalies, and occurrences. Make better decisions by using ArcGIS GeoAnalytics Server or ArcGIS Velocity to analyze large datasets and visualize insights.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Intel} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Rta} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Real-Time Analysis</h2>
                    <p>Process and filter data as it's received, including filtering for specific attributes in high-velocity and high-volume data. Determine spatial relationships of events with an established geofence such as a point, line, or polygon.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Big data analysis</h2>
                    <p>Leverage the power of distributed computing to analyze and aggregate large amounts of stored data. Solve problems more effectively by using lightning-fast analytical tools, from the simple to the complex. Detect how data has changed over time and pinpoint areas of interest. Find relationships, patterns, and anomalies easily using the out-of-the-box analytics in GeoAnalytics Server or ArcGIS Velocity.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={BigData} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={ThreeDVisual} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>3D Visualization</h2>
                    <p>You can give your data more dimensions by creating the location exactly as it was made or develop your vision. Your raw data can be valuable in helping you solve issues and grasp the big picture. You will also be able to spot patterns, trends, and hidden linkages, using 3D models and data visualizations.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default Visualize