import React, { useState, useEffect } from 'react';
import Navbar from '../Components/Nav';
import Sidebar from '../Components/MobileNav';
import HeroSection from '../Components/Hero';
import OurProducts from '../Components/OurProducts';
import MessageUs from '../Components/MessageUs';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';
import BASE_URL from '../apiConfig';
import Token from '../api';
import axios from 'axios';
import logo from '../assets/images/jamitan.png';
import {
  PopupCard,
  CloseButton,
  Logo,
  PopupOverlay,
} from '../Components/popUpElements';
import DOMPurify from 'dompurify';


const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [announcementData, setAnnouncementData] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Create a config object with the Authorization header
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    // Make an Axios GET request to the API with the Authorization header
    axios
      .get(`${BASE_URL}/announcement`, config)
      .then((response) => {
        // If the API returns a response, set the data in the state
        setAnnouncementData(response.data[0]);
        setShow(response.data[0]?.showAnnouncement)
      })
      .catch((error) => {
        // If there's an error (e.g., no response from the API), you can handle it here
        console.error('Error fetching data:', error);
        // Display the default content in case of an error
      });
  }, []); // Empty dependency array to run the effect only once


  const handleClosePopup = () => {
    setShow(false); // Hide the popup card
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const sanitizedAnnouncementData = DOMPurify.sanitize(announcementData?.announcement);

  return (
    <div>
      <Helmet>
        <title>Jamitan - Home</title>
        <meta name="description" content="Jamitan | Home Page" />
      </Helmet>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {show && announcementData && (
        <PopupOverlay>
          <PopupCard>
            <Logo src={logo} alt="Logo" style={{marginBottom:'5px'}} />
            <div style={{ padding: '20px', overflow:'auto' }} dangerouslySetInnerHTML={{ __html: sanitizedAnnouncementData }} />
            <CloseButton onClick={handleClosePopup}>X</CloseButton>
          </PopupCard>
        </PopupOverlay>
      )}
      <HeroSection />
      <OurProducts />
      <MessageUs />
      <Footer />
    </div>
  );
};

export default Home;
