import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import RoadsPic from '../assets/images/roads.png'
import Planning from '../assets/images/planning1.png'
import Proj from '../assets/images/projectDelivery.png'
import Environment from '../assets/images/environmental.png'
import Operations from '../assets/images/operations.png'
import Maintenance from '../assets/images/Maintenance.png'
import Safety from '../assets/images/Safety.png'
import { Helmet } from 'react-helmet';


const Roads = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Roads</title>
        <meta name="description" content="Jamitan | Roads" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Roads</HeroH1>
        </HeroContainer3>
        <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
                <Image src={RoadsPic} alt='Image10'  />
            </div>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>With the power of location intelligence, departments of transportation (DOTs) can improve mobility and ensure a safe and reliable transportation system for people. Esri geographic information system (GIS) technology for roads and highways can transform your organization into a spatial data- and performance-driven agency. Use smart maps to better plan, manage, and maintain your infrastructure.</p>
        </div>
        <div style={{textAlign:'center', paddingBottom:'20px'}}>
            <h1>Business Areas</h1>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Planning} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Planning</h2>
                    <p>Use GIS to collect information from across your organization and put it in the hands of planners so they can make the best decisions. Use location to organize data on the current condition of assets and transportation system performance and deficiencies. Then you can make strategic decisions about where to invest. GIS in the transportation planning process helps you effectively communicate your road and highway plan, which helps citizens understand why and where you are investing public transportation funds. </p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Environmental Sustainability</h2>
                    <p>You are tasked with balancing road and highway operations with environmental sustainability goals. It's essential to ensure that the transportation network supports a healthy economy and respects delicate ecosystems. Whether balancing new projects with environmental limitations, wildlife preservation constraints, or stormwater runoff considerations, GIS provides a way to ensure that you responsibly monitor and manage resources. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Environment} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Proj} alt='Image12' style={{width:'720px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Project Delivery</h2>
                    <p>Using GIS in transportation project delivery helps ensure that transportation capital improvement projects (CIP) are delivered in a safe and timely manner while meeting contracted design and materials standards. The Project Delivery solution is all about data—especially for creating a web-based, seamless data flow from office to field. Ensure that projects are delivered on time, within budget, and per specifications. We provide a range of tools to help agencies efficiently share information from desk to field and with contractors. GIS for transportation project delivery allows you to enhance the delivery of CIP.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Operations</h2>
                    <p>Getting the optimal performance from monitoring and managing your existing network is the role of your road and highway operations department. With ArcGIS, you can put together real-time information from disparate sources. Use smart maps to get a comprehensive view of your road and highway operations to understand the full road network through geospatial data. Gain greater operational control to ensure smooth and safe traffic operations, and keep your transportation system operating at peak performance. With GIS real-time traffic mapping, you can view all of your data, including traffic, weather, incidents, congestion, and construction, on a single integrated map. Your traffic management centre can make use of these features to provide real-time information to the public on the state of the roads. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Operations} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Maintenance} alt='Image12' style={{width:'720px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Maintenance</h2>
                    <p>Preserving the life of your existing assets is more than good practice—it is a requirement under current federal regulations. Our GIS allows you to comprehensively organize your road assets, understand their location and condition, and integrate your systems with the leading asset management solutions for your road and highway maintenance. we provide mobile solutions designed to help your highway maintenance workers collect and inspect your assets in the field. Give your fieldworkers the information they need to be more productive and keep assets in top condition. ArcGIS provides the foundation for your asset inventory, so you know exactly what you have and need to maintain for a healthy transportation system. Additionally, GIS can help you quickly understand asset performance, develop a plan for preserving assets, and monitor your crews as they maintain critical assets.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Safety</h2>
                    <p>With GIS for road and highway safety, you can capture and analyze accurate traffic crash data. Historical records help you understand where accidents have occurred in the past and what measures you can take to prevent them in the future. GIS can put information together from across your organization, creating a single authoritative source. Information products can combine analyses and map-based visualizations that help analysts understand areas of concern and countermeasures that will ensure the safest roadways possible. GIS for road and highway safety can help establish a system of record for the safety analyst, bringing a wealth of information together to facilitate better analysis and interagency collaboration. Our solution can also help you analyse your traffic crash data to reveal patterns and unlock a deeper understanding of your safety data. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Safety} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default Roads