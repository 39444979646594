import styled from 'styled-components'
import dottedWave from '../assets/images/dott-wave.svg'
import { Link } from 'react-router-dom';


export const CardGrid = styled.div`
  padding: 2% 8% 8%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 20px; /* Adjust the gap between cards as needed */

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
    grid-template-rows: repeat(2, 1fr); /* 4 rows on mobile */
  }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
        grid-template-rows: repeat(1, 1fr);
    }
`;
export const CardGrid2 = styled.div`
  padding: 2% 8% 8%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 columns on desktop */
  gap: 20px; /* Adjust the gap between cards as needed */

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
    grid-template-rows: repeat(2, 1fr); /* 4 rows on mobile */
  }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
        grid-template-rows: repeat(1, 1fr);
    }
`;
export const CardGridValues = styled.div`
  padding: 2% 8% 8%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 20px; /* Adjust the gap between cards as needed */

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr); /* 2 columns on mobile */
  }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
        grid-template-rows: repeat(1, 1fr);
    }
`;
export const CardGridGallery = styled.div`
  padding: 2% 8% 8%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 20px; /* Adjust the gap between cards as needed */

  @media (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 2fr); /* 2 columns on mobile */
    grid-template-rows: repeat(2, 1fr); /* 4 rows on mobile */
  }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
        grid-template-rows: repeat(1, 1fr);
    }
`;

export const Card = styled(Link)`
  background-color: #ECF1F3;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  text-decoration: none;
  color: black;
  /* Add other card styles as needed */
`;

export const CardContainer = styled.div`
   /* Set the width as needed */
  margin-left: 8%;
  margin-right: 8%;
   /* Set the height as needed */
  border-radius: 30px; /* Apply a border radius of 30px */
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, #0485C2, #157393);
    z-index: -1; /* Place the gradient background behind the content */
  }

  /* Background image */
  background-image: url(${dottedWave}); /* Set your background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff; /* Set text color to white or the desired color */
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;

export const TextDiv = styled.div`
    width: 70%;
    display: flex;
    gap:10px;
    align-items: center;
    @media screen and (max-width: 768px) {
       display: none;
    }
`
export const TextDiv1 = styled.div`
    width: 30%;
    padding: 4%;
    @media screen and (max-width: 768px) {
       width: inherit;
    }
`

export const ImageContainers = styled.div`
  width: 219px;
  height: 219px;
  border-radius: 14px;
  position: relative;
  overflow: hidden; /* Ensure that the image stays within the container */
`

export const Images = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */

`;