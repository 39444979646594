import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv1, Image } from '../Components/ResusableElements';
import { Helmet } from 'react-helmet';
import conso from '../assets/images/consultation.jpg'


const Consultation = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Consultation</title>
        <meta name="description" content="Jamitan Mapping Tech" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Consultation</HeroH1>
        </HeroContainer3>
        <div style={{display:'flex', justifyContent:'center', paddingTop:'4%'}}>
                <Image src={conso} alt='Image10' style={{borderRadius:'10px'}} />
            </div>
        <FlexDiv1 style={{marginTop:'10px'}}>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Consultation</h2>
                    <p>We use a consultative approach while working with you to comprehend your company's needs and present strengths. You can collaborate with our advisors on geospatial strategy, get specialized technical assistance, and much more. 
                        We collaborate with your team to share best practices and knowledge, and we work with you to develop solutions. Our goal is to assist you where you need it so you may accomplish your organizational goals and build up your internal capabilities.
                    </p>
                </div>
            </BlueContainer>
        </FlexDiv1>
        <Footer />
    </div>
  )
}

export default Consultation