import styled from 'styled-components';

export const PopupOverlay = styled.div`
  /* Styles for the overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9998; /* Lower z-index than the popup card to ensure the card is on top */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupCard = styled.div`
  /* Styles for the popup card */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative; /* Position relative to allow for stacking */
  z-index: 9999; /* Higher z-index to ensure the card is on top */
  text-align: center;

  /* Fixed dimensions for the popup card */
  width: 400px; /* Adjust to your preferred width */
  height: 500px; /* Adjust to your preferred height */

  /* Make the content scrollable when it exceeds the card's dimensions */
  overflow: auto;

  /* Set a maximum height for the scrollable content */
  max-height: 100%; /* Adjust as needed */

  /* Add any other styling you need for the popup card */

  /* Media query for responsiveness */
  @media (max-width: 768px) {
    /* Adjust styles for mobile devices */
    width: 90%;
    height: auto; /* Allow height to adjust as needed */
  }
`;


export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: red;
  }

  /* Add any other styling you need for the close button */
`;

export const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  /* Add any other styling you need for the logo */
`;
