import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer4, HeroH1 } from '../Components/HeroElements';
import {Image } from '../Components/ResusableElements';
import KhatibLogo from '../assets/images/khatib.png'
import { Helmet } from 'react-helmet';
import {FaLocationDot} from 'react-icons/fa6'
import {BsTelephoneFill, BsGlobe} from 'react-icons/bs'


const Khatib = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Partners - Khatib & Alami (K&A)</title>
        <meta name="description" content="Jamitan | Partners" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer4 style={{height:'249px', display:'grid', alignItems:'center', background:'#006e80'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Partners / Khatib & Alami (K&A)</HeroH1>
        </HeroContainer4>
        <div style={{display:'flex', justifyContent:'center', margin:'50px'}}>
                <Image src={KhatibLogo} alt='Image10'  />
        </div>
        <div style={{textAlign:'center'}}>
            <h4>Khatib & Alami (K&A)</h4>
            <p>Beirut, Lebanon</p>
        </div>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <h3>Overview</h3>
            <p style={{lineHeight:'1.5em'}}>K&A is a top Geospatial Systems Integrator and Digital Services with successful projects grossing over 500. Founded more than 50 years ago by Prof. Mounir El-Khatib and Dr. Zuheir Alami, K&A has garnered years of experience in offering enterprise implementations, system integration, and solutions.<br /><br />
            With more than 30 international offices and over 6000 experts, K&A has efficiently carried out countless enterprise solutions across industries including Power, Water and Wastewater, Energy, Municipalities, Security, Land Management, Real Estate, Telecommunications, Transportation, and Planning.  <br /><br/>
            K&A operates mostly in the Middle East and Africa. They operate in the following countries: Lebanon, United Arab Emirates (UAE), Kingdom of Saudi Arabia (KSA), Nigeria, Jordan, Bahrain, Iraq, Kuwait, Oman, Qatar, Egypt, Algeria, Sudan, Libya, Cameroon, Côte d'Ivoire, Ethiopia, Ghana, Kenya, Morocco, Rwanda, Sénégal, Tanzania, Tunisia, Uganda, India, Pakistan, and United States of America.
            </p>
            <h3>Services</h3>
            <p style={{lineHeight:'1.5em'}}>K&A offers comprehensive implementation of geospatial & system integration solutions. Enterprise GIS, GIS strategy and planning, business case development, consulting services, needs and requirements definition, system architecture design, system integration, hosting services, database design and building, data conversion and migration, database management and administration, application design and development, capacity building, training services, technical support, and operation and maintenance are some of our areas of expertise. <br /><br />
            K&A also holds ArcGIS Indoors, Utility Network Management, and Release Ready Specialty recognition and offer services to implement Esri solution. They specifically provides the following services; Application Development, Business Case Development, Data Conversion/Migration, Data Model & Database Design, GIS Strategy and Planning, Hosting Services, Implementation, Needs and Requirements, System Architecture and Design, System Integration, Training Services.  </p>
            <h3>Contact Information</h3>
            <p>
            <p><span style={{marginRight:'5px'}}><FaLocationDot /></span>Location: Al Akhtal Al Saghir St Beirut 1105-2100, Lebanon.</p>
            <p><span style={{marginRight:'5px'}}><BsTelephoneFill /></span>Telephone: 961 1 929717</p>
            <p><span style={{marginRight:'5px'}}><BsGlobe /></span>Website: <a href='https://www.khatibalami.com' target='_blank' rel="noreferrer">www.khatibalami.com</a></p>
            </p>
        </div>
        <Footer />
    </div>
  )
}

export default Khatib