import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer4, HeroH1 } from '../Components/HeroElements';
import {Image } from '../Components/ResusableElements';
import AwsLogo from '../assets/images/aws.png'
import { Helmet } from 'react-helmet';
import {FaLocationDot} from 'react-icons/fa6'
import { BsGlobe} from 'react-icons/bs'


const Aws = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Partners - AWS</title>
        <meta name="description" content="Jamitan | Partners" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer4 style={{height:'249px', display:'grid', alignItems:'center', background:'#006e80'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Partners / AWS</HeroH1>
        </HeroContainer4>
        <div style={{display:'flex', justifyContent:'center', margin:'50px'}}>
                <Image src={AwsLogo} alt='Image10'  />
        </div>
        <div style={{textAlign:'center'}}>
            <h4>Amazon Web Services (AWS)</h4>
            <p>Washington State, United States</p>
        </div>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <h3>Overview</h3>
            <p style={{lineHeight:'1.5em'}}>Amazon Web Services (AWS) is the most extensive and widely used cloud in the world, with more than 200 fully functional services available from data centers across the world. AWS is being used by millions of clients, including the largest corporations, the most successful start-ups, and top government organizations, to cut costs, improve flexibility, and accelerate innovation.<br /><br/>
            Launched in 2006, AWS has since become the world’s most comprehensive cloud computing service. Rather than purchasing, operating, and maintaining physical data centers and servers, a cloud provider like AWS allows you technological services like computing power, storage, and databases on an as-needed basis  <br /><br />
            Adopted across various industries including Energy, Financial Services, Healthcare, Government, Education, Media & Entertainment, Manufacturing, Retail, Agriculture and so on, AWS has unrivalled experience, maturity, dependability, security, and performance and is designed to be the most adaptable and safe cloud computing environment currently available.            </p>
            <h3>Services</h3>
            <p style={{lineHeight:'1.5em'}}>AWS offers various services including: Data Archiving, Backup & Storage, Blckchain Services, Cloud Migration, Cloud Operations, Content Delivery, Database Migration, Data Lakes & Analytics, Front-End Web & Mobile Development, Modern Application Development, High Performance Computing, Hybrid Cloud Architectures, Machine Learning, Scientific Computing, and Websites. <br /><br />
            AWS products are, amongst others, designed to manage databases, enable scalable storage in the cloud, develop and evolve applications through cycles of rapid innovation, simplify and centralize operations in hybrid and multicloud environments, launch and manage virtual private servers, and build, train and deploy machine learning models at scale </p>
            <h3>Contact Information</h3>
            <p>
            <p><span style={{marginRight:'5px'}}><FaLocationDot /></span>Location: 410 Terry Avenue North, Seattle, Washington, U.S.A.</p>
            <p><span style={{marginRight:'5px'}}><BsGlobe /></span>Website: <a href='https://www.aws.amazon.com' target='_blank' rel="noreferrer">www.aws.amazon.com</a></p>
            </p>
        </div>
        <Footer />
    </div>
  )
}

export default Aws