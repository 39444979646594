import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv } from '../Components/ResusableElements';
import { Helmet } from 'react-helmet';
import { Card, CardGrid2 } from '../Components/ProductsElements';


const Cloud = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Cloud Services</title>
        <meta name="description" content="Jamitan Technical support" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Cloud Services</HeroH1>
        </HeroContainer3>
            <div style={{display:'flex', justifyContent:'center', paddingTop:'2%'}}>
                <h1>We also offer managed cloud services.</h1>
            </div>
            <CardGrid2>
            <Card to='#'>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Find the Best Approach</div>
                    <p>Our partners and experts at Esri Managed Cloud Services as well as Amazon Web Services will collaborate with you to determine the best cloud strategy for your business, whether that means adopting a fully cloud-based infrastructure or merely enhancing your planned or current capabilities with a cloud deployment.</p>
            </Card>
            <Card to='#'>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Establish a Cloud Environment</div>
                <p>No matter how sophisticated or cutting-edge your demands are, Esri and AWS has the best teams in the business to support you. Get started with a top-notch system without the hassle of figuring it out on your own.</p>
            </Card>
            <Card>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Get Continuous Assistance</div>
                <p>
                    Esri and AWS professionals handle uptime, security, maintenance, and performance after your cloud environment is live and keep in touch with your business.
                </p>
            </Card>
            <Card to='#'>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Transform your company</div>
                <p>Esri Managed Cloud Services and Amazon Web Services can also assist you in optimizing your organization's cloud capabilities to get the outcomes you are after as your company goals and demands evolve.</p>
            </Card>
        </CardGrid2>
        <Footer />
    </div>
  )
}

export default Cloud