import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv,FlexDiv2, Image } from '../Components/ResusableElements';
import Image10 from '../assets/images/image10.svg'
import Image11 from '../assets/images/image11.svg'
import Image12 from '../assets/images/image12.svg'
import { Helmet } from 'react-helmet';


const Mapping = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Mapping Services</title>
        <meta name="description" content="Jamitan Mapping Tech" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Mapping</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}} className='special-text'>
            <p>Using dynamic mapping software from Esri, you can create interactive maps to view and explore your data. You can find and improve the story your data is telling by using robust analysis tools and map styles. Use our vast data library's content to enliven your maps. To make your maps unique, use basic maps and customized symbols. Share interesting maps that help shift the narrative of your data.</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Image10} alt='Image10'  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Map your data</h2>
                    <p>Create a map with your data and for the first time learn about spatial relationships. Spreadsheets, KML, GeoJSON, and common geospatial files are just a few of the file types that Esri's mapping software can read. We offer in-depth data hosting, but you can also host via your own infrastructure.</p>
                    <p>Create multimodal routes by geocoding your addresses to create points on a map. By using the most recent data while making decisions, you can develop maps that show data dynamically as soon as it is updated.</p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Personalize your map</h2>
                    <p>Using unique symbols, colors, and base maps, you can design and personalize your maps. By putting your data on a vintage, imaginative, or modern base map, you can make your data stand out. The base maps can be modified, or you can simply create your own.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Image11} alt='Image11'  />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Image12} alt='Image12'  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Enhance your data</h2>
                    <p>ArcGIS Living Atlas of the World, the most extensive collection of global geographic information, is included with every ArcGIS mapping tool, allowing you to further your understanding and reveal links by adding authoritative data to your map, such as demographics, imagery, boundaries, and live feeds.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default Mapping