import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Ops from '../assets/images/aviation-ops.png'
import RealTime from '../assets/images/aviation-realtime.png'
import { Helmet } from 'react-helmet';


const AviationOps = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Aviation Operations</title>
        <meta name="description" content="Jamitan | Aviation Operations" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Aviation Operations</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>Successful airports need to carefully choreograph a host of complex activities to achieve smooth operations. GIS allows you to integrate dynamic information from various systems to get a comprehensive view of airport operations. Understand bottlenecks quickly and make fast, informed decisions that drive smooth operations.</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Ops} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Single Dynamic View</h2>
                    <p>Monitoring all your operational systems in one comprehensive view allows you to get ahead of emerging problems. Visualize all moving assets, understand emerging issues, and take proactive control with a centralized operations dashboard. GIS gives you visibility of your airside, terminal, and landside systems for greater control and smoother operations.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Real-time operational performance view</h2>
                    <p>With location analytics, you can monitor and improve your operational performance in real time. Integrate systems like an airport operations database with GIS to understand consequences of delayed flights and weather disruptions. Then, make proactive adjustments to maintain the most efficient operations. Continuously monitor and improve your performance with GIS—a hallmark of smart airport management.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={RealTime} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default AviationOps