import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/home';
import About from './Pages/about';
import Contact from './Pages/contact';
import Mapping from './Pages/mapping';
import Services from './Pages/services';
import OnlineSolution from './Pages/onlineSolution';
import DesktopSoftware from './Pages/desktopSoftware';
import GisEnterprise from './Pages/gisEnterprise';
import FieldMaps from './Pages/fieldsMap';
import GeoBim from './Pages/geobim';
import Visualize from './Pages/visualize';
import Imagery from './Pages/imagery';
import Technical from './Pages/technical';
import Training from './Pages/training';
import Consultation from './Pages/consultation';
import Cloud from './Pages/cloudServices';
import Telecoms from './Pages/telecoms';
import LandAdmin from './Pages/landadmin';
import NatStat from './Pages/nationalStats';
import RealEstate from './Pages/realEstate';
import Architecture from './Pages/architecture';
import NatSec from './Pages/nationalSecurity';
import Esri from './Pages/esri';
import Khatib from './Pages/Khatib';
import Aws from './Pages/aws';
import Maxar from './Pages/maxar';
import Products from './Pages/products';
import Industries from './Pages/industries';
import Transport from './Pages/transport';
import Ports from './Pages/ports';
import Roads from './Pages/roads';
import Rail from './Pages/rails';
import Aviation from './Pages/aviation';
import AviationOps from './Pages/aviationOps';
import AviationInfra from './Pages/aviationInfra';
import AviationSec from './Pages/aviationSec';
import Emergency from './Pages/emergency';
import EmOps from './Pages/emOps';
import FireDept from './Pages/fireDept';
import Crr from './Pages/crr';

function App() {
  return (
    <div className='App'>
        <Routes>
          <Route path="/" element={<Home />} scrollToTop />
          <Route path='/about' element={<About />} scrollToTop/>
          <Route path='/contact' element={<Contact />} scrollToTop/>
          <Route path='/mapping' element={<Mapping />} scrollToTop/>
          <Route path='/services' element={<Services />} scrollToTop/>
          <Route path='/industries' element={<Industries />} scrollToTop/>
          <Route path='/products' element={<Products />} scrollToTop/>
          <Route path='/online-solution' element={<OnlineSolution />} scrollToTop/>
          <Route path='/desktop-software' element={<DesktopSoftware/>} scrollToTop/>
          <Route path='/gis-enterprise' element={<GisEnterprise />} scrollToTop/>
          <Route path='/field-maps' element={<FieldMaps/>} scrollToTop/>
          <Route path='/geobim' element={<GeoBim/>} scrollToTop/>
          <Route path='/visualization' element={<Visualize />} />
          <Route path='/imagery-and-remote-sensing' element={<Imagery />} scrollToTop/>
          <Route path='/technical-support' element={<Technical />} scrollToTop/>
          <Route path='/training' element={<Training />} scrollToTop/>
          <Route path='/consultation' element={<Consultation />} scrollToTop/>
          <Route path='/cloud-services' element={<Cloud/>} scrollToTop/>
          <Route path='/industries/telecommunications' element={<Telecoms/>} scrollToTop/>
          <Route path='/industries/transportation' element={<Transport />} scrollToTop/>
          <Route path='/transportation/ports' element={<Ports />} scrollToTop/>
          <Route path='/transportation/rail' element={<Rail />} scrollToTop/>
          <Route path='/transportation/roads-highways' element={<Roads />} scrollToTop/>
          <Route path='/industries/land-administration' element={<LandAdmin/>} scrollToTop/>
          <Route path='/industries/national-statistics' element={<NatStat/>} scrollToTop/>
          <Route path='/industries/real-estate' element={<RealEstate/>} scrollToTop/>
          <Route path='/industries/architecture-engineering-construction' element={<Architecture />} scrollToTop/>
          <Route path='/industries/national-security' element={<NatSec />} scrollToTop/>
          <Route path='/industries/emergency-management' element={<Emergency />} scrollToTop/>
          <Route path='/emergency-management/operations' element={<EmOps />} scrollToTop/>
          <Route path='/emergency-management/fire-department-accreditation' element={<FireDept />} scrollToTop/>
          <Route path='/emergency-management/community-risk-reduction' element={<Crr />} scrollToTop/>
          <Route path='/industries/aviation' element={<Aviation />} scrollToTop/>
          <Route path='/aviation/operations' element={<AviationOps />} scrollToTop/>
          <Route path='/aviation/infrastructure' element={<AviationInfra />} scrollToTop/>
          <Route path='/aviation/safety-security' element={<AviationSec />} scrollToTop/>
          <Route path='/partners/esri' element={<Esri />} scrollToTop/>
          <Route path='/partners/khatib' element={<Khatib scrollToTop/>} />
          <Route path='/partners/aws' element={<Aws />} scrollToTop/>
          <Route path='/partners/maxar' element={<Maxar />} scrollToTop/>
        </Routes>
    </div> 
  )
}

export default App;
