import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import EmData from '../assets/images/emData.png'
import Realtime from '../assets/images/real-time.png'
import { Helmet } from 'react-helmet';


const EmOps = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Emergency Management Operations</title>
        <meta name="description" content="Jamitan | Emergency Management Operations" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Emergency Management Operations</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>Fire and emergency medical services (EMS) personnel continually analyze data. Every response generates data for after-action fire department reporting, performance measures, and the identification of successful operations. GIS supports these reviews with rich data analysis from computer-aided dispatch and records management systems, precise measurements, and streamlined data sharing with decision-makers.</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Realtime} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Real-time analysis</h2>
                    <p>Incident command staff can use GIS to monitor fire and EMS operations sourced from computer-aided dispatch or records management data. GIS can be used to visualize recent incident reports such as calls for service. GIS software also allows command staff to review daily activity for each station or district, maintain incident awareness, understand short-term trends, and monitor response times</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Data-driven resource deployment</h2>
                    <p>GIS is used to analyze, measure, and share data with decision-makers. This data can come from computer-aided dispatch software, records management systems, community risk reduction activities, or any source deemed appropriate by the agency. GIS can be used to identify gaps in the current deployment model, ensure that resources are deployed appropriately, and help address risks identified in the community risk assessment.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={EmData} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default EmOps