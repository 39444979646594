import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import {HiExternalLink} from 'react-icons/hi'
import { Helmet } from 'react-helmet';
import Fire from '../assets/images/fire.png'
import Realtime from '../assets/images/real-time.png'
import Crr from '../assets/images/crr.png'


const Emergency = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industries - Emergency Management</title>
        <meta name="description" content="Jamitan | Emergency Management" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Emergency Management</HeroH1>
        </HeroContainer3>
        <div style={{textAlign:'left', padding:'8%', paddingBottom:'0'}} className='special-text'>
            <p>Response and recovery require effective collaboration among the public safety community, federal and state agencies, and local governments. Esri's ArcGIS software offers unique capabilities for applying location analytics to your preparedness, response, and recovery practices.</p>
        </div>
        <div style={{padding: '8%'}}>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Realtime} alt='Image10' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Operations and Performance Monitoring</h2>
                        <p>Fire and emergency medical services (EMS) personnel continually analyze data. Every response generates data for after-action fire department reporting, performance measures, and the identification of successful operations. GIS supports these reviews with rich data analysis from computer-aided dispatch and records management systems, precise measurements, and streamlined data sharing with decision-makers.</p>
                        <span style={{color:'#0492C2'}}><a href='/emergency-management/operations' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                    
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Fire Department Accreditation</h2>
                        <p>Firefighters make critical decisions based on limited information every day. Agencies should strive to generate verified data for emergency and nonemergency needs to provide responders with the right data at the right time. Through the power of location intelligence, organizations can now adopt a smarter, more integrative approach to safety. </p>
                        <span style={{color:'#0492C2'}}><a href='/emergency-management/fire-department-accreditation' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Fire} alt='Image11' />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Crr} alt='Image12'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Community Risk Reduction</h2>
                        <p>Community Risk Reduction (CRR) coordinates emergency operations with prevention and mitigation efforts across the community and at the fire department level. The involvement of frontline personnel is critical for field data collection. A comprehensive CRR program improves the health of the community, firefighter safety, and the accreditation process. With GIS technology, your agency can more effectively identify hazards and develop an efficient CRR response strategy designed to reduce the risks facing occupants and firefighters.</p>
                        <span style={{color:'#0492C2'}}><a href='/emergency-management/community-risk-reduction' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
        </div>
        <Footer />
    </div>
  )
}

export default Emergency