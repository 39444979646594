import styled, {keyframes} from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'
import hero from '../assets/images/contacthero.svg'
import map from '../assets/images/global-network.svg'
import { Link } from 'react-router-dom';

/*const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const ping = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
`; */

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index: 1;

    :before {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background:  transparent;
        z-index: 2;
    }

     @media screen and (max-width: 768px) {
        height: 600px;
        
    }
`
export const HeroContainer2 = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to left,#0492C2, #1520AC);
    z-index: -1; /* Place the gradient background behind the content */
  }

  /* Background image */
  background-image: url(${hero}); /* Set your background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff; /* Set text color to white or the desired color */
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;
export const HeroContainer3 = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to left,#0492C2, #1520AC);
    z-index: -1; /* Place the gradient background behind the content */
  }

  /* Background image */
  background-image: url(${map}); /* Set your background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff; /* Set text color to white or the desired color */
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;
export const HeroContainer4 = styled.div`
  position: relative;


  /* Background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff; /* Set text color to white or the desired color */
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */

  /* Add any additional styles as needed */
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    animation: ${bounce} 8s infinite;
`

export const VideoBg = styled.image`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-item: center;
`

export const HeroH1 = styled.h1`
    color: #1E1E1E;
    font-size:48px;
    text-align: center;
    font-weight: 900;
    line-height: 58.09px;

    @media screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 30px;
        
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
        line-height: 45px;
    }

`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #1E1E1E;
    font-size: 18px;
    font-family: Inter;
    text-align: center;
    line-height: 21.78px;


    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ArrowForward = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`
export const PartnerStrip = styled.div`
    width: 100%;
    background: #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;

    @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const ImageContainer = styled(Link)`
    width: 86px;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px; /* Add spacing between image containers */
    box-sizing: border-box;
    margin-right: 86px;

  @media (max-width: 768px) {
    width: 43px; /* Half the size on mobile */
    height: 43px; /* Half the size on mobile */
    margin-right: 43px;
  }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    filter: grayscale(100%); 

    &:hover {
    filter: none; /* Remove the filter on hover to show the original image */
  }
`