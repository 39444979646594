import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import {HiExternalLink} from 'react-icons/hi'
import { Helmet } from 'react-helmet';
import Infra from '../assets/images/aviation-infra.png'
import Ops from '../assets/images/aviation-ops.png'
import Safety from '../assets/images/aviation-safety.png'


const Aviation = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industries - Aviation</title>
        <meta name="description" content="Jamitan | Aviation" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Aviation</HeroH1>
        </HeroContainer3>
        <div style={{textAlign:'left', padding:'8%', paddingBottom:'0'}} className='special-text'>
            <p>Managing modern airport operations is increasingly complex. Demand for air travel is growing along with the need to cost-effectively manage infrastructure and increase revenues while protecting the safety and security of passengers and facilities. Esri's ArcGIS platform allows you to integrate information from across your organization and understand activity in real time. Smart maps and spatial analysis help you satisfy regulatory compliance and improve airport management.</p>
        </div>
        <div style={{padding: '8%'}}>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Ops} alt='Image10' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Operations</h2>
                        <p>Successful airports need to carefully choreograph a host of complex activities to achieve smooth operations. GIS allows you to integrate dynamic information from various systems to get a comprehensive view of airport operations. Understand bottlenecks quickly and make fast, informed decisions that drive smooth operations.</p>
                        <span style={{color:'#0492C2'}}><a href='/aviation/operations' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                    
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Infrastructure and Asset Management</h2>
                        <p>Modern aviation is synonymous with substantial infrastructure investments. To ensure performance and long-term viability, GIS technology is used to oversee everything from airfield inspections and pavement management to terminal work orders and runway signage. GIS technology helps airports control costs and optimize asset performance, preserving and prolonging the life cycle of their most valuable assets. </p>
                        <span style={{color:'#0492C2'}}><a href='/aviation/infrastructure' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Infra} alt='Image11' />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Safety} alt='Image12'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Safety and Security</h2>
                        <p>Esri's ArcGIS helps security personnel at all stages of their workflow. From assessing disparate types of data and security threats to performing strategic planning and coordinating field personnel with mobile technology, Esri provides a common operating platform that's accessible anytime from any location and on any device. This common operational platform supports collaboration and information sharing, giving all involved parties greater situational awareness to make better decisions in real time. GIS also allows airports to meet Federal Aviation Administration requirements to improve security.</p>
                        <span style={{color:'#0492C2'}}><a href='/aviation/safety-security' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
        </div>
        <Footer />
    </div>
  )
}

export default Aviation