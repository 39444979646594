import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Infra from '../assets/images/aviation-infra.png'
import Performance from '../assets/images/aviation-performance.png'
import { Helmet } from 'react-helmet';


const AviationInfra = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Aviation Infrastructure</title>
        <meta name="description" content="Jamitan | Aviation Infrastructure" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Aviation Infrastructure</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>Modern aviation is synonymous with substantial infrastructure investments. To ensure performance and long-term viability, GIS technology is used to oversee everything from airfield inspections and pavement management to terminal work orders and runway signage. GIS technology helps airports control costs and optimize asset performance, preserving and prolonging the life cycle of their most valuable assets. </p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Infra} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Asset and Maintenance Management</h2>
                    <p>While runways and taxiways will always be among an airport's largest capital investments, knowing the location and condition of all your assets is critical to effectively maintaining the operational efficiency of the airport.
At Jamitan, we partner with the world's leading asset and work order management solution providers so that GIS can promote communication and information exchange among numerous business systems, producing seamless analysis and regulatory compliance of asset performance.
</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Asset performance monitoring</h2>
                    <p>Asset managers must be proactive when it comes to asset and maintenance management. As part of an always-on enterprise, airport managers need to be responsive to keep the airport operating 24/7. Esri technology gives you analytical capabilities to understand your asset performance—no matter how small the asset—so you can avoid costly disruptions.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Performance} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default AviationInfra