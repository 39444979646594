import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import {HiExternalLink} from 'react-icons/hi'
import { Helmet } from 'react-helmet';
import Ports from '../assets/images/ports.png'
import Roads from '../assets/images/roads.png'
import Rail from '../assets/images/rail.png'


const Transport = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industries - Transportation</title>
        <meta name="description" content="Jamitan Transportation" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Transportation</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}}>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Ports} alt='Image10' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Ports</h2>
                        <p>Large international ports are complex organizations. Port staff manage assets and facilities. They optimize transportation infrastructure and port performance—all while minimizing traffic congestion and environmental impacts. And they must coordinate all these activities to ensure unencumbered throughput that protects profit and sustainability.
For optimal performance, leaders need access to the best real-time port data and spatial intelligence to make the best decisions. Become a smart port with GIS for ports and thrive in today's dynamic port environment.
</p>
                        <span style={{color:'#0492C2'}}><a href='/transportation/ports' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                    
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Roads and Highways</h2>
                        <p>With the power of location intelligence, departments of transportation (DOTs) can improve mobility and ensure a safe and reliable transportation system for people. Esri geographic information system (GIS) technology for roads and highways can transform your organization into a spatial data- and performance-driven agency. Use smart maps to better plan, manage, and maintain your infrastructure.</p>
                        <span style={{color:'#0492C2'}}><a href='/transportation/roads-highways' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Roads} alt='Image11' />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Rail} alt='Image12'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Rail</h2>
                        <p>Whether you are moving freight across a state or across countries, rail remains one of the most cost-effective modes of transportation. For companies supporting global supply chains or simply bringing crops to market, rail plays an ever-critical role in our modern transportation systems.
Rail can reach its full potential with GIS technology and information systems that improve performance through real-time decision-support systems. This moment calls for a comprehensive digital transformation and an ability to leverage location intelligence. Esri can help power that transformation.
</p>
                        <span style={{color:'#0492C2'}}><a href='/transportation/rail' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
        </div>
        <Footer />
    </div>
  )
}

export default Transport