import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, Image } from '../Components/ResusableElements';
import onlineSolution from '../assets/images/onlinesolu.jpg'
import { Helmet } from 'react-helmet';


const OnlineSolution = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Online Solution</title>
        <meta name="description" content="Jamitan Mapping Tech" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Online Solution</HeroH1>
        </HeroContainer3>
            <div style={{display:'flex', justifyContent:'center', paddingTop:'4%'}}>
            <BlueContainer>
                <Image src={onlineSolution} alt='Image10' style={{borderRadius:'10px'}} />
            </BlueContainer>
                
            </div>
            <BlueContainer>
                <div style={{padding:'8%', lineHeight:'30px'}}>
                    <p>ArcGIS Online is a software-as-a-service (SaaS) offering that organizations can use to create, manage, and share geospatial content in the cloud.<br></br>
                    With ArcGIS Online, Esri's web-based mapping tool, you can create interactive online maps. As you engage with the data, you can zoom in, and conduct a search on the map, you will gain fresh viewpoints and improved details. Gain location intelligence by using smart, data-driven mapping techniques and simple analytical tools. Working together to create and use maps will help you communicate successfully across your organization.<br></br>
                    ArcGIS Online allows you and your organization access location services such as basemap layers, geocoding, routing, geo-enrichment, and spatial analysis.<br></br>
                    You can also build apps with web, native, scripting, and open-source APIs for JavaScript, iOS, Android, .NET, Java, Qt, and Python.<br></br>
                    ArcGIS Online provides you options to use or customize no-code/low-code app builders with products like ArcGIS Instant Apps, ArcGIS Dashboards, ArcGIS StoryMaps, and ArcGIS Experience Builder<br></br>
                    Be certain that with ArcGIS Online you can securely create, store, and share content and data with team members and others in your organization. </p>
                </div>   
            </BlueContainer>
        <Footer />
    </div>
  )
}

export default OnlineSolution