import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Conso from '../assets/images/consultation.jpg';
import Cloud from '../assets/images/cloudservices.jpg';
import {HiExternalLink} from 'react-icons/hi'
import { Helmet } from 'react-helmet';
import Image10 from '../assets/images/image10.svg'
import Opps from '../assets/images/ops.png'
import ImageryPic from '../assets/images/imagery.jpg'
import TechnicalPic from '../assets/images/techsupport.jpg'
import Train from '../assets/images/train.png'



const Services = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Our Services</title>
        <meta name="description" content="Jamitan Services" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Our Services</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}}>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Image10} alt='Image10' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Mapping</h2>
                        <p>Using dynamic mapping software from Esri, you can create interactive maps to view and explore your data. You can find and improve the story your data is telling by using robust analysis tools and map styles. Use our vast data library's content to enliven your maps. To make your maps unique, use basic maps and customized symbols. Share interesting maps that help shift the narrative of your data.</p>
                        <span style={{color:'#0492C2'}}><a href='/mapping' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                    
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Visualization & Analytics</h2>
                        <p>Control how your real-time, streaming data is displayed—either dynamically or historically—and view it the way you want to use it. From maps to dashboards, visualize your real-time data in context to make the right decisions.</p>
                        <span style={{color:'#0492C2'}}><a href='/visualization' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Opps} alt='Image11' />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={ImageryPic} alt='Image12' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Imagery & Remote Sensing</h2>
                        <p>Global imagery enables visibility into operations and prospects in business, government, science, and research. Today, satellites, crewed aircraft, and drones all gather more imagery than ever before, giving us an almost real-time view of the whole planet. In a geospatial technology system that combines imaging and GIS, you may extract more information from every pixel and turn static photographs into dynamic digital representations of our environment.</p>
                        <span style={{color:'#0492C2'}}><a href='/imagery-and-remote-sensing' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Technical Support</h2>
                        <p>We care about the success of your organization, which is why we are here to help. Our team of support experts will handle any problem affecting your organization if you need assistance with your product of software deployment and technical issues.</p>
                        <span style={{color:'#0492C2'}}><a href='/technical-support' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={TechnicalPic} alt='Image11' />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Train} alt='Image12' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Training</h2>
                        <p>We are here to help you on your learning path. We provide materials to support you in achieving your objectives, whether you are new to ArcGIS or an experienced GIS professional who wants to master the newest technology</p>
                        <span style={{color:'#0492C2'}}><a href='/training' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Consultation</h2>
                        <p>We use a consultative approach while working with you to comprehend your company's needs and present strengths. You can collaborate with our advisors on geospatial strategy, get specialized technical assistance, and much more. </p>
                        <span style={{color:'#0492C2'}}><a href="/consultation" style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Conso} alt='Image11'  />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Cloud} alt='Image12'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Cloud Services</h2>
                        <p>Our partners and experts at Esri Managed Cloud Services as well as Amazon Web Services will collaborate with you to determine the best cloud strategy for your business, whether that means adopting a fully cloud-based infrastructure or merely enhancing your planned or current capabilities with a cloud deployment.</p>
                        <span style={{color:'#0492C2'}}><a href='/cloud-services' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
        </div>
        <Footer />
    </div>
  )
}

export default Services