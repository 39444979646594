import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, Image } from '../Components/ResusableElements';
import TechnicalPic from '../assets/images/technical.png'
import { Helmet } from 'react-helmet';
import { Card, CardGrid } from '../Components/ProductsElements';


const Technical = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Technical support</title>
        <meta name="description" content="Jamitan Technical support" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Technical support</HeroH1>
        </HeroContainer3>
        <div style={{padding: '4%'}}>
        </div>
            <CardGrid>
            <Card to='#'>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Value</div>
                    <p>We care about the success of your organization, which is why we are here to help. Our team of support experts will handle any problem affecting your organization if you need assistance with your product of software deployment and technical issues.</p>
            </Card>
            <Card to='#'>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Quality</div>
                <p>Our technical help is thorough, qualified, effective, and supported by procedures that guarantee your issue receives the care it requires. We consistently receive good customer satisfaction scores as a result of our emphasis on quality.</p>
            </Card>
            <Card>
                <div style={{fontWeight:'600', fontSize:'20px'}}>Assurance</div>
                <p>
                When you contact us, we will make every effort to assist you. Our team of customer advocates has access to additional engineering and development resources as needed to identify solutions. 
                </p>
            </Card>
        </CardGrid>
        <Footer />
    </div>
  )
}

export default Technical