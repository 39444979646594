import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv1, Image } from '../Components/ResusableElements';
import Train from '../assets/images/train.png'
import { Helmet } from 'react-helmet';


const Training = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Training</title>
        <meta name="description" content="Jamitan Training" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Training</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}}>
        </div>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Image src={Train} alt='Image10'  />
            </div>
        <FlexDiv1 style={{marginTop:'10px'}}>
            <BlueContainer>
                <div style={{padding:'8%'}} className='special-text'>
                    <p>We are here to help you on your learning path. We provide materials to support you in achieving your objectives, whether you are new to ArcGIS or an experienced GIS professional who wants to master the newest technology. 
                        You and your team can create products and services that give you a competitive advantage by equipping them with the ArcGIS software and skills they need to achieve all your organizational goals.
                    </p>
                </div>
            </BlueContainer>
        </FlexDiv1>
        <Footer />
    </div>
  )
}

export default Training