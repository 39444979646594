import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer4, HeroH1 } from '../Components/HeroElements';
import { Image } from '../Components/ResusableElements';
import EsriLogo from '../assets/images/esri.png'
import { Helmet } from 'react-helmet';
import {FaLocationDot} from 'react-icons/fa6'
import {BsTelephoneFill, BsGlobe} from 'react-icons/bs'


const Esri = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Partners - Esri</title>
        <meta name="description" content="Jamitan | Partners" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer4 style={{height:'249px', display:'grid', alignItems:'center', background:'#006e80'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Partners / Esri</HeroH1>
        </HeroContainer4>
        <div style={{display:'flex', justifyContent:'center', margin:'50px'}}>
                <Image src={EsriLogo} alt='Image10'  />
        </div>
        <div style={{textAlign:'center'}}>
            <h4>Environmental Systems Research Institute (ESRI)</h4>
            <p>California, United States</p>
        </div>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <h3>Overview</h3>
            <p style={{lineHeight:'1.5em'}}>In terms of mapping, location intelligence, and geographic information system (GIS) software, Esri dominates the world market. They have provided clients with geographic science and geospatial analytics, sometimes known as "The Science of Where,". They adopt cutting-edge GIS technology to solve problems using a spatial approach and are dedicated to combining technology and research to create a sustainable future.<br /><br />
            Founded in 1969 by Jack and Laura Dangermond, Esri now has users among 90% of Fortune 100 businesses, the majority of national governments, 30,000 towns and local governments, all 50 US states, 12,000 colleges and more than 300,000 organizations. With more than 5000 employees across 73 countries, Esri software, ArcGIS is adopted all around the globe. <br /><br/>
            Esri has 49 offices worldwide, including 11 research and development centers. Some of these locations include United States of America, Canada, Mexico, Guatamala, United Kingdom, Lebanon, Ghana, Morocco, Kenya, Yemen, South Africa, Iraq, Tunisia and Mauritius. 
            </p>
            <h3>Services</h3>
            <p style={{lineHeight:'1.5em'}}>Esri offers Mapping, Field Operations, Spatial Analysis & Data Science, Imagery and Remote Sensing, Indoor GIS, Real-Time Visualization & Analystics, 3D Visualization, Data Management and Developer APIs –all through their software ArcGIS (ArcGIS Online, ArcGIS Pro, ArcGIS Enterprise & ArcGIS Platform). <br /><br />
                Esri also provides technical support, training, consultancy services and cloud management services across industries. Esri operates in the following industries; Aviation, Architecture, Engineering & Construction (AEC), Public Safety, Facilities Manageement, Health & Human Services, Transportation, and Telecommunication. </p>
            <h3>Contact Information</h3>
            <p>
            <p><span style={{marginRight:'5px'}}><FaLocationDot /></span>Location: 380 New York Street, Redlands, California, USA</p>
            <p><span style={{marginRight:'5px'}}><BsTelephoneFill /></span>Telephone: +1 909 793 2853, +234 (0) 929 228 21 (Nigeria)</p>
            <p><span style={{marginRight:'5px'}}><BsGlobe /></span>Website: <a href='https://www.esri.com' target='_blank' rel="noreferrer">www.esri.com</a></p>
            </p>
        </div>
        <Footer />
    </div>
  )
}

export default Esri