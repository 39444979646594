import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Mast from '../assets/images/mast.png'
import Celltower from '../assets/images/ops.jpg'
import Git from '../assets/images/GIT.png'
import Plan from '../assets/images/Planning.png'
import { Helmet } from 'react-helmet';


const Telecoms = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Telecommunications</title>
        <meta name="description" content="Jamitan | Telecommunications" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Telecommunications</HeroH1>
        </HeroContainer3>
        <div style={{display:'flex', justifyContent:'center', paddingTop:'40px'}}>
                <Image src={Mast} alt='Image10' style={{borderRadius:'10px'}} />
            </div>
        <div style={{padding: '8%', paddingTop:'2%'}} className='special-text'>
            <p>Effective network management is essential for all telecommunications service providers, and location intelligence is at the core of this. ArcGIS from Esri provides location intelligence to enhance thorough knowledge and wise decision-making. Teams are most equipped to address today's expectations and complete the organization's next-generation network projects by utilizing the system of record, insight, and engagement ArcGIS offers.</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Celltower} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Operations & Maintenance</h2>
                    <p>ArcGIS unifies Operations Support Systems (OSS) and Business Support Systems (BSS) through a shared understanding of location and maps while enhancing situational awareness through real-time network and field operations because GIS leverages the one thing all telecommunication operations have in common –location. Enhancing network operations and maintenance not only makes communication networks stronger but also offers additional assistance to the systems, procedures, and personnel responsible for managing and maintaining telecommunication networks. </p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Planning, Designing & Construction</h2>
                    <p>Using ArcGIS technology, telecommunication operators can assess capacity and discover mission-critical market possibilities, enabling them to make investments that will maximize their return on investment (ROI). With Esri's ArcGIS, new planning, engineering, and design technologies are available to telecommunications service providers (CSPs). As they invest in and create telecom materials like expanded fibre and software-defined networks, leaders in this industry can speed up time to market through remote engineering while retaining superior project operational awareness. Additionally, in line with best practices and industry standards, ArcGIS provides connectivity modelling of assets in 2D and 3D. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Plan} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Git} alt='Image12' style={{width:'720px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>GIS for Information Technology</h2>
                    <p>By utilizing location services and maps in new Internet of Things (IoT) technology and services for smart cities, telecommunications businesses can leverage GIS to adapt more quickly while obtaining a competitive edge. ArcGIS is an enterprise solution that supports conventional business lines while enabling innovative goods and services through emerging technology. Telecommunications service providers must accelerate digital transformation initiatives to keep up with the market and customer expectations.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default Telecoms