import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import {HiExternalLink} from 'react-icons/hi'
import { Helmet } from 'react-helmet';
import Land from '../assets/images/LandAdmin.png'
import Pla from '../assets/images/pla.png'
import Market from '../assets/images/market.png'
import Transport from '../assets/images/transport.png'
import Mast from '../assets/images/mast.png'
import Environment from '../assets/images/Environment.png'
import Intelligence from '../assets/images/Intelligence.png'
import RealTime from '../assets/images/real-time.png'
import Aviation from '../assets/images/aviation.png'


const Industries = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industries</title>
        <meta name="description" content="Jamitan Industries" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Industries</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%', paddingBottom:'10%'}}>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Mast} alt='Image10'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Telecommunications</h2>
                        <p>Effective network management is essential for all telecommunications service providers, and location intelligence is at the core of this. ArcGIS from Esri provides location intelligence to enhance thorough knowledge and wise decision-making. Teams are most equipped to address today's expectations and complete the organization's next-generation network projects by utilizing the system of record, insight, and engagement ArcGIS offers.</p>
                        <span style={{color:'#0492C2'}}><a href='/industries/telecommunications' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                    
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Land Administration</h2>
                        <p>Governments are better able to manage land information, conduct property assessment and analysis, and communicate with the general public when all land information is contained in a comprehensive GIS-based land administration system.</p>
                        <span style={{color:'#0492C2'}}><a href='/industries/land-administration' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Land} alt='Image11'  />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Pla} alt='Image12' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>National Statistics</h2>
                        <p>Population censuses are one of the main data sources for executing policies aiming at equitable socioeconomic development and environmental sustainability. Governments are aware of the need to employ digital technology and GIS -a revolutionary tool that enables businesses to understand how geography affects their operations and financial results. ArcGIS can improve statistical systems by incorporating location intelligence, enhancing accuracy, and boosting efficiency and effectiveness.</p>
                        <span style={{color:'#0492C2'}}><a href='/industries/national-statistics' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Real Estate</h2>
                        <p>The real estate industry is a vital sector in global economies, encompassing property development, investment, sales, and management. It offers growth opportunities, wealth creation, diversification, and innovation, with great potential for continued success and innovation. Real estate their companies can gain a competitive advantage in the market by incorporating ArcGIS Enterprise into operations. ArcGIS Enterprise is a comprehensive, scalable, and secure geospatial platform that enables them to make data-driven decisions, improve operational efficiency, and gain a competitive advantage.</p>
                        <span style={{color:'#0492C2'}}><a href='/industries/real-estate' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Market} alt='Image11' />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Transport} alt='Image12' />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Transportation</h2>
                        <p>Location plays a critical role in all transportation activities. A location-based system of engagement founded in GIS technology makes your data easy to understand, analyze, and act on. Thrive with location at the forefront of operations. As congestion increases in our urban centers, the need for effective public transportation has significantly increased. At the same time, urban mobility patterns are changing rapidly, with a more diverse public bringing changing expectations of public transport services. Meeting these challenges and transforming operations require location data and powerful analysis from GIS. </p>
                        <span style={{color:'#0492C2'}}><a href='/industries/transportation' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Architecture, Engineering and Construction</h2>
                        <p>Architects, engineers, builders, and owners use ArcGIS solutions to improve workflows, bring context to projects, and increase collaboration throughout project life cycles. GIS is helping architecture, engineering, and construction (AEC) firms build smart communities and assets for the future. </p>
                        <span style={{color:'#0492C2'}}><a href='/industries/architecture-engineering-construction' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={Environment} alt='Image11'  />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Intelligence} alt='Image12'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>National Security</h2>
                        <p>Our solutions for security operations provide business leaders, security managers, and security teams with real-time situational awareness, command and control capabilities, and advanced analytics.</p>
                        <span style={{color:'#0492C2'}}><a href='/industries/national-security' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
            <FlexDiv2>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Emergency Management</h2>
                        <p>Response and recovery require effective collaboration among the public safety community, federal and state agencies, and local governments. Esri's ArcGIS software offers unique capabilities for applying location analytics to your preparedness, response, and recovery practices.</p>
                        <span style={{color:'#0492C2'}}><a href='/industries/emergency-management' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
                <BlueContainer>
                    <Image src={RealTime} alt='Image11'  />
                </BlueContainer>
            </FlexDiv2>
            <FlexDiv>
                <BlueContainer>
                    <Image src={Aviation} alt='Image12'  />
                </BlueContainer>
                <BlueContainer>
                    <div style={{padding:'8%'}}>
                        <h2>Aviation</h2>
                        <p>Managing modern airport operations is increasingly complex. Demand for air travel is growing along with the need to cost-effectively manage infrastructure and increase revenues while protecting the safety and security of passengers and facilities. Esri's ArcGIS platform allows you to integrate information from across your organization and understand activity in real time. Smart maps and spatial analysis help you satisfy regulatory compliance and improve airport management</p>
                        <span style={{color:'#0492C2'}}><a href='/industries/aviation' style={{fontWeight: '600', fontSize:'16px', display:'flex', textDecoration:'none',color:'#0492C2' }}>View more<HiExternalLink style={{fontSize:'20px', marginLeft:'5px'}} /></a> </span>
                    </div>
                </BlueContainer>
            </FlexDiv>
        </div>
        <Footer />
    </div>
  )
}

export default Industries