import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Safety from '../assets/images/aviation-safety.png'
import Incident from '../assets/images/aviation-incident.png'
import { Helmet } from 'react-helmet';


const AviationSec = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Aviation Infrastructure</title>
        <meta name="description" content="Jamitan | Aviation Infrastructure" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Aviation Infrastructure</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>Modern aviation is synonymous with substantial infrastructure investments. To ensure performance and long-term viability, GIS technology is used to oversee everything from airfield inspections and pavement management to terminal work orders and runway signage. GIS technology helps airports control costs and optimize asset performance, preserving and prolonging the life cycle of their most valuable assets. </p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Safety} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Complete security operational view</h2>
                    <p>Airport security systems are often characterized by input from a large array of sources. To complicate matters, the data from these various sources are typically siloed and unfiltered. Integrating disparate technologies into a framework that delivers situational intelligence is the core challenge for many security professionals. GIS helps address this problem. By integrating all sensor and surveillance feeds, airports gain access to all their control systems in one place, producing comprehensive security operational views.
</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Incident management</h2>
                    <p>With ArcGIS, you can capture, document, visualize, and understand any incident in near real-time, enhancing your ability to intelligently respond. Whether an emergency in the terminal, a runway incursion, or another type of incident, gain real-time data visibility and improve incident response.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Incident} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default AviationSec