import React,{useState,useEffect} from 'react';
import { Logo, MobileIcon, Nav, NavItem, NavLinks, NavMenu, NavbarContainer } from './NavElements'
import logo from '../assets/images/jamitan.png'
import {FaBars} from 'react-icons/fa'
import Submenu from './NavElements';


const Navbar = ({toggle}) => {
  return (
    <Nav>
        <NavbarContainer>
            <Logo to='/'>
                <img src={logo} alt='Jamitan' style={{width:'144px'}} />
            </Logo>
            <MobileIcon onClick={toggle}>
                <FaBars/>
            </MobileIcon>
            <NavMenu>
                <NavItem style={{paddingTop:'30px'}}>
                    <Submenu
                        sublinks={{
                            label: 'Products',
                            links: [
                            { label: 'Online Solution', url: '/online-solution' },
                            { label: 'Desktop Software', url: '/desktop-software' },
                            { label: 'GIS Enterprise', url: '/gis-enterprise' },
                            { label: 'Field Maps', url: '/field-maps' },
                            { label: 'GeoBIM', url: '/geobim' },
                            ],
                        }}
                    />
                </NavItem>
                <NavItem style={{paddingTop:'30px'}}>
                    <Submenu
                        sublinks={{
                            label: 'Services',
                            links: [
                            { label: 'Mapping', url: '/mapping' },
                            { label: 'Visualization & Analytics', url: '/visualization' },
                            { label: 'Imagery & Remote Sensing', url: '/imagery-and-remote-sensing' },
                            { label: 'Technical support', url: '/technical-support' },
                            { label: 'Training', url: '/training' },
                            { label: 'Consultation', url: '/consultation' },
                            { label: 'Cloud Services', url: '/cloud-services' },
                            // Add more service links as needed
                            ],
                        }}
                    />
                </NavItem>
                <NavItem style={{paddingTop:'30px'}}>
                    <Submenu
                        sublinks={{
                            label: 'Industries',
                            links: [
                            { label: 'Telecommunications', url: '/industries/telecommunications' },
                            { label: 'Land Administration', url: '/industries/land-administration' },
                            { label: 'National Statistics', url: '/industries/national-statistics' },
                            { label: 'Real Estate', url: '/industries/real-estate' },
                            { label: 'Transportation', url: '/industries/transportation' },
                            { label: 'Architecture Engineering & Construction', url: '/industries/architecture-engineering-construction' },
                            { label: 'National Security', url: '/industries/national-security' },
                            { label: 'Emergency Management', url: '/industries/emergency-management' },
                            { label: 'Aviation', url: '/industries/aviation' },
                            // Add more service links as needed
                            ],
                        }}
                    />
                </NavItem>
                <NavItem>
                    <NavLinks to='https://blog.jamitan.com' >Blog</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks to='https://blog.jamitan.com/gallery' >Gallery</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks to='/about' >About Us</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks to='/contact' >Contact Us</NavLinks>
                </NavItem>
            </NavMenu>
        </NavbarContainer>
    </Nav>
  )
}

export default Navbar