import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import CrrPic from '../assets/images/crr.png'
import Cord from '../assets/images/cord.png'
import Mitigate from '../assets/images/mitigate.png'
import { Helmet } from 'react-helmet';


const Crr = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Emergency Management | Community Risk Reduction </title>
        <meta name="description" content="Jamitan | Emergency Management Community Risk Reduction" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Community Risk Reduction</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>Firefighters make critical decisions based on limited information every day. Agencies should strive to generate verified data for emergency and nonemergency needs to provide responders with the right data at the right time. Through the power of location intelligence, organizations can now adopt a smarter, more integrative approach to safety.</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={CrrPic} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Implement a CRR Strategy</h2>
                    <p>Fire departments can use location intelligence to coordinate the community risk reduction cycle functions. Personnel can share risk assessment data seamlessly; assign CRR activities; and evaluate performance measures through planning, operations, and program review activities.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Coordinate to facilitate the CRR process</h2>
                    <p>GIS can manage the creation and assignment of community risk reduction activities. These applications help fire service command staff and departments identify high-risk levels, assign work to appropriate fire personnel, and review performance measures.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Cord} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Mitigate} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>GIS in mitigation activities</h2>
                    <p>GIS applications provide a focused approach to reducing risks. The process begins with the risk assessment. These risks can be human-made or naturally occurring. The assessment identifies risks in the community and allows public safety agencies to prioritize those risks. This initial step in preparedness allows for mitigation efforts, planning, and the ability to make data-driven decisions to deploy resources.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default Crr