import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import GeobimPic from '../assets/images/geobim.png'
import Project from '../assets/images/project.png'
import Never from '../assets/images/never.png'
import Connect from '../assets/images/connect.png'
import Seamless from '../assets/images/seamless.png'
import { Helmet } from 'react-helmet';


const GeoBim = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>GeoBIM</title>
        <meta name="description" content="Jamitan Mapping Tech" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>GeoBIM</HeroH1>
        </HeroContainer3>
        <div style={{display:'flex', justifyContent:'center', paddingTop:'20px'}}>
                <BlueContainer>
                    <Image src={GeobimPic} alt='Image10'  />
                </BlueContainer>
            </div>
        <div style={{padding: '8%', paddingTop:'1%'}} className='special-text'>
            <p>Using data from various systems in a geographic context, ArcGIS GeoBIM provides teams with an innovative, user-friendly web-based experience to explore and engage on Building Information Modeling (BIM) projects and concerns. Teams working in Architecture, Engineering, Construction (AEC), and operations may quickly collaborate with linked data and documentation in customizable web apps.
            </p>
        </div>
        <FlexDiv style={{marginTop:'10px'}}>
            <BlueContainer>
                <Image src={Project} alt='Image10'  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Project Your Activity on One Map</h2>
                    <p>ArcGIS GeoBIM's user-friendly web interface allows you to explore and access project data, issues, and documents in a geospatial context.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Never Reorganize</h2>
                    <p>Keep your data and documents organized in their original places, and use a single web-based interface to access your GIS and BIM project materials.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Never} alt='Image11' />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Connect} alt='Image12' />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Connect Direct to Data Source</h2>
                    <p>By immediately linking to BIM projects, data, and documents located on different systems, you may lessen the need to convert your data on a regular basis.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Seamless Communication</h2>
                    <p>Stakeholders can investigate project information and issues by using user-friendly ArcGIS GeoBIM apps. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Seamless} alt='Image11' />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default GeoBim