import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, Image } from '../Components/ResusableElements';
import ImageryPic from '../assets/images/imagery.png'
import { Helmet } from 'react-helmet';
import { Card, CardGrid } from '../Components/ProductsElements';


const Imagery = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Imagery & Remote Sensin</title>
        <meta name="description" content="Jamitan Imagery & Remote Sensin" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Imagery & Remote Sensing</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}} className='special-text'>
            <p>Global imagery enables visibility into operations and prospects in business, government, science, and research. Today, satellites, crewed aircraft, and drones all gather more imagery than ever before, giving us an almost real-time view of the whole planet. In a geospatial technology system that combines imaging and GIS, you may extract more information from every pixel and turn static photographs into dynamic digital representations of our environment.
            </p>
        </div>
            <div style={{display:'flex', justifyContent:'center', paddingBottom:'4%'}}>
                <Image src={ImageryPic} alt='Image10' style={{borderRadius:'10px'}} />
            </div>
            <CardGrid>
            <Card to='#'>
                <div style={{fontWeight:'600', fontSize:'20px'}}>A comprehensive system for imagery</div>
                    <p>From scheduling drone flights to developing deep learning models, carry out every imaging and remote sensing workflow in a single software environment.</p>
            </Card>
            <Card to='#'>
                <div style={{fontWeight:'600', fontSize:'20px'}}>More ways to use your remotely sensed data</div>
                <p>Access robust data management, mapping, and analysis tools supported by ArcGIS, the world's most popular location intelligence program.</p>
            </Card>
            <Card>
                <div style={{fontWeight:'600', fontSize:'20px'}}>A geographic approach to data integration</div>
                <p>
                Utilize GIS to seamlessly combine photos with other geographic data—creating richer information products that eventually lead to more informed decisions. 
                </p>
            </Card>
        </CardGrid>
        <Footer />
    </div>
  )
}

export default Imagery