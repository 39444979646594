import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Market from '../assets/images/market.png'
import Portfolio from '../assets/images/portfolio.jpg'
import Viz from '../assets/images/3dvisualization.jpg'
import Corp from '../assets/images/corpo.jpg'
import { Helmet } from 'react-helmet';


const RealEstate = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Real Estate </title>
        <meta name="description" content="Jamitan | Real Estate" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Real Estate</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}} className='special-text'>
            <p>The real estate industry is a vital sector in global economies, encompassing property development, investment, sales, and management. It offers growth opportunities, wealth creation, diversification, and innovation, with great potential for continued success and innovation. Real estate their companies can gain a competitive advantage in the market by incorporating ArcGIS Enterprise into operations. ArcGIS Enterprise is a comprehensive, scalable, and secure geospatial platform that enables them to make data-driven decisions, improve operational efficiency, and gain a competitive advantage.</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Market} alt='Image10' style={{width:'500px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Market Research And Site Analysis</h2>
                    <p>Find your client's next best location, offer advice on how to optimize a portfolio of sites, and make property and facility decisions faster. In real estate, time is of the essence. ArcGIS real estate market research and site analysis can help your brokers stand out from the competition.</p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Portfolio Management</h2>
                    <p>Investors and property managers can quickly understand how their real estate investments are performing across town or around the world by utilizing thousands of global datasets. Access precise local data about all of your real estate assets quickly and easily on any device, anywhere, and at any time. Access data on occupancy, building maintenance, property valuation, and other topics.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Portfolio} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Viz} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>3D Visualization and Imaging</h2>
                    <p>Using 3D models and stunning renderings, you can digitally transform your real estate property management workflows throughout design, construction, and marketing. With high-quality, fully rendered 3D models for virtual walk-throughs—on any device, anywhere—you can help prospective tenants and clients visualize their business on any device, anywhere.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Corporate Services</h2>
                    <p>Real estate teams require access to precise, authoritative data to create state-of-the-art marketing materials and engaging analyses. Provide your brokers and executives with access to curated, authoritative spatial data about their properties and assets with ArcGIS. Easily create and share maps and applications across the enterprise to speed up decisions and close deals.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Corp} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default RealEstate