import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import FieldsMap from '../assets/images/fieldMaps.png'
import NavigatePic from '../assets/images/navigate.png'
import Understand from '../assets/images/understand.png'
import Plan from '../assets/images/plan.png'
import Capture from '../assets/images/capture.png'
import Monitor from '../assets/images/monitor.png'
import Share2 from '../assets/images/share2.png'
import { Helmet } from 'react-helmet';


const FieldMaps = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Field Maps</title>
        <meta name="description" content="Jamitan Mapping Tech" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Field Maps</HeroH1>
        </HeroContainer3>
        <div style={{display:'flex', justifyContent:'center'}}>
                <Image src={FieldsMap} alt='Image10'  />
            </div>
        <div style={{padding: '8%', paddingTop:'1%'}} className='special-text'>
            <p>The influence of geography enhances field operations' coordination and effectiveness. Utilize field apps to lessen or perhaps eliminate the need for paper. Through specifically designed data gathering apps, increase the field assets' data accuracy. To minimize errors, increase efficiency, and save money, make sure that office and field personnel use the same reliable data.
            </p>
        </div>
        <FlexDiv style={{marginTop:'10px'}}>
            <BlueContainer>
                <Image src={Plan} alt='Image10' style={{width:'500px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Plan</h2>
                    <p>Bring the field and the office in tandem. Identify where work needs to be done and effectively organize and deploy resources by utilizing location. The foundation on which field activity planning is done is made up of your already-existing authoritative data.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Navigate</h2>
                    <p> Even when offline, you can still access your information and your roads. Utilize the most effective routes to ensure that you arrive on time and complete the task at hand. By using voice-guided routing, which takes into account the type of vehicle being driven and any local traffic regulations, drivers are able to maintain their focus on the road.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={NavigatePic} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Understand} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Understand</h2>
                    <p>Take the digital maps from your organization with you wherever you go. Use a current map to locate resources, points of interest, and to get a sense of what's nearby. When conducting inspections, responding to natural disasters, or engaging in other activities that benefit from spatial context, emphasize your spatial awareness and understanding.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Capture</h2>
                    <p>Change obsolete paper-based processes. Data collection tools for mobile workers of various technical levels can make it simple for employees to execute reliable data gathering and asset inspections in any setting. Data collected in the field enters your system of record right away, easing life cycle management and assisting in improving decision-making.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Capture} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Monitor} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Monitor</h2>
                    <p>Take decisions quickly. Descriptive dashboards and maps aid in making well-informed decisions. Monitoring, following, and reporting real-time data feeds, location traces, and actions that are focused on what matters most can help you convey the state of field operations to management. To inform constituents about activities and events that affect them, display maps and dashboards.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Share</h2>
                    <p>The success of a project may depend on successful communication of information. Within a single system, it is simple to scale to the unique needs of your circumstance whether working as a single team or with numerous businesses. For both internal and external stakeholders, it is simple to control data access so that everyone has access to the information they require.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Share2} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default FieldMaps