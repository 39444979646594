import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Pla from '../assets/images/pla.png'
import Coll from '../assets/images/collect.jpg'
import DataAnalysis from '../assets/images/Da.png'
import { Helmet } from 'react-helmet';


const NatStat = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - National Statistics </title>
        <meta name="description" content="Jamitan | National Statistics" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>National Statistics</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%'}} className='special-text'>
            <p>Population censuses are one of the main data sources for executing policies aiming at equitable socioeconomic development and environmental sustainability. Governments are aware of the need to employ digital technology and GIS -a revolutionary tool that enables businesses to understand how geography affects their operations and financial results. ArcGIS can improve statistical systems by incorporating location intelligence, enhancing accuracy, and boosting efficiency and effectiveness.</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Pla} alt='Image10' style={{width:'500px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Planning</h2>
                    <p>For any census, careful planning is necessary to know where to count and how to count in a limited amount of time. In the generic statistical business process model, mapping is typically seen as one of the most important activities thereby providing the geographic context for the data utilized in the actual enumeration process. Enumeration areas (EAs) can be improved, edited, and validated using ArcGIS tools.</p>
                </div>
                
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Collect, Produce & Manage Statistical Data</h2>
                    <p>Statistics that are geospatially integrated help us understand patterns better, offer perspective and predictive insights, and further improve our capacity to decide wisely for our communities. By utilizing GIS and earth observation data, such as imagery and remote sensing, we make sure that data like the address list for a Census is current, accurate, and comprehensive. </p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Coll} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={DataAnalysis} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Data Analysis</h2>
                    <p>With GIS, you can utilize spatial statistics to verify the existence of patterns as well as make spatiotemporal forecasts using historical trends, supplementary data, and present trends and patterns. You can use ArcGIS to analyze, measure, predict spatial effects from data relationships and advance your scientific research.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <Footer />
    </div>
  )
}

export default NatStat