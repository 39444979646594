import React, { useState } from 'react'
import { CardContainer, Div, TextDiv, TextDiv1, ContactFormContainer, FormField,MessageTextArea, SubmitButton, Div1 } from './MessageUsElements'
import { PopupCard, CloseButton, Logo, PopupOverlay } from './popUpElements';
import logo from '../assets/images/jamitan.png'

const MessageUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState(null);
  const [submitMessage, setSubmitMessage] = useState('Submit')

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage('Submitting...')
    try {
      const response = await fetch('https://api.jamitan.com/contact-us.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
         await response.json();
         setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          message: '',
        });
        setSubmitMessage('Submit')
        setResponseMessage('Message sent successfully'); // Set success message
      } else {
        console.error('Error:', response.status);
        setResponseMessage('Message could not be sent. Please try again later'); // Set error message
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('Message could not be sent. Please try again later'); // Set error message
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClosePopup = () => {
    setResponseMessage(null); // Hide the popup card
  };

  return (
    <>
      <CardContainer style={{marginTop:'8%', marginBottom:'8%'}}>
        <Div>
            <TextDiv1>
            <div style={{textAlign:'start',  color:'#000', fontWeight:'700', fontSize:'40px', lineHeight:'48.41px'}}>
                We are glad to hear<br /> from you!
            </div>
            <p style={{textAlign:'start',fontWeight:'400',lineHeight:' 19.36px', fontSize:'16px',color:'#000'}}>Message us about your project or idea, we will<br /> respond to you via the email address used to <br/> submit this form.</p>
            </TextDiv1>
            <TextDiv>
              <ContactFormContainer onSubmit={handleSubmit}>
                <Div1 style={{gap: '10px'}}>
                    <FormField type="text" placeholder="First Name" id='firstName' name="firstName" value={formData.firstName} onChange={handleChange} required />
                    <FormField type="text" placeholder="Last Name" id='lastName' name="lastName" value={formData.lastName} onChange={handleChange} required />
                </Div1>
                <Div1 style={{gap: '10px'}}>
                    <FormField type="tel" placeholder="Phone Number" id='phoneNumber' name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} required />
                    <FormField type="email" placeholder="Email Address" id="email"  name="email"  value={formData.email}  onChange={handleChange} required/>
                </Div1>
                
                <MessageTextArea placeholder="Write a message..."
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required />
                <SubmitButton>{submitMessage}</SubmitButton>
              </ContactFormContainer>
            </TextDiv>
        </Div>
      </CardContainer>
      {responseMessage && (
        <PopupOverlay>
          <PopupCard>
            <Logo src={logo} alt="Logo" />
            <p>{responseMessage}</p>
            <CloseButton onClick={handleClosePopup}>X</CloseButton>
          </PopupCard>
        </PopupOverlay>
      )}
    </>
  )
}
export default MessageUs