import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import logo from '../assets/images/jamitan.png'
import {ImLinkedin} from 'react-icons/im'
import {FaXTwitter} from 'react-icons/fa6'
import {AiFillInstagram} from 'react-icons/ai'
import BASE_URL from '../apiConfig'
import Token from '../api'
import axios from 'axios';
import { Link as Linq } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: transparent;
  color: #1E1E1E;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Align headers to the top */
  margin-left: 5%;
  margin-right: 5%;
`;

const LogoColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin-right: 40px;
`;

const LogoImage = styled.img`
  width: 100px;
  height: auto;
`;

const Description = styled.p`
  font-size: 14px;
  margin-bottom: 30px;
`;


const Column = styled.div`
  flex: 1;
  min-width: 200px;
  margin-right: 20px; /* Add spacing between columns */

  &:last-child {
    margin-right: 0; /* Remove spacing for the last column */
  }

  @media (max-width: 768px) {
    margin-right: 0; /* Remove spacing on smaller screens */
    margin-bottom: 20px; /* Add spacing between columns on smaller screens */
  }
`;

const Title = styled.h4`
  font-size: 18px;
  margin-bottom: 10px;
`;

const Links = styled.ul`
  list-style: none;
  padding: 0;
`;

const Link = styled.li`
  margin-bottom: 5px;
`;

const HorizontalLine = styled.hr`
  width: 1240px;
  top: 405px;
  left: 100px;
  border: 1px solid #D9D9D9;
  transform: rotate(-0deg);
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Copyright = styled.div`
  flex: 1;
  font-size: 14px;
  line-height: 1.5;

`;

const SocialIcons = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const SocialIcon = styled.a`
  margin-left: 20px;
  text-decoration: none;
  color: #1e1e1e;

  &:hover {
    color: #0485C2;
  }
`;

const Footer = () => {
  const [contactUsData, setContactUsData] = useState(null);
  
    useEffect(() => {
      // Create a config object with the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
  
      // Make an Axios GET request to the API with the Authorization header
      axios.get(`${BASE_URL}/contact`, config)
        .then(response => {
          // If the API returns a response, set the data in the state
          setContactUsData(response.data[0]);
        })
        .catch(error => {
          // If there's an error (e.g., no response from the API), you can handle it here
          console.error('Error fetching data:', error);
          // Display the default content in case of an error
        });
    }, []);
  return (
    <>
    <div style={{background:'#fff', marginTop:'40px'}}>
        <FooterContainer style={{marginTop: '40px'}}>
        <LogoColumn>
            <LogoImage src={logo} alt="Logo" />
            <Description>
            We help build mapping and spatial analytical software, designed to support the actualization of government and business objectives around the globe.
            </Description>
        </LogoColumn>
        <Column>
            <Title>Company</Title>
            <Links>
            <Link><a href='/products' style={{textDecoration:'none'}}>Products</a></Link>
            <Link><a href='/industry' style={{textDecoration:'none'}}>Industry</a></Link>
            <Link><a href='/services' style={{textDecoration:'none'}}>Services</a></Link>
            <Link><a href='/about' style={{textDecoration:'none'}}>About Us</a></Link>
            <Link><a href='https://blog.jamitan.com' style={{textDecoration:'none'}}>Blog</a></Link>
            </Links>
        </Column>
        <Column>
            <Title>Contact</Title>
            <p>Email: {contactUsData ? contactUsData.email : "info@jamitan.com"}</p>
            {contactUsData ? (<p dangerouslySetInnerHTML={{ __html: contactUsData.phone }}/>) : <p>Phone: +234 8124849569 <br /> +234 8162609982</p>}
        </Column>
        <Column>
            <Title>Location</Title>
            <p>{contactUsData ? contactUsData.address : "4b Abagana Close, Off Damaturu Crescent, Garki 2 Abuja."}</p>
        </Column>
        </FooterContainer>
        <HorizontalLine />
        <FooterContainer>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
          <Copyright>© {new Date().getFullYear()} - Jamitan, Inc </Copyright>
          <SocialIcons>
            <SocialIcon href={contactUsData ? contactUsData.linkedin : "https://ng.linkedin.com/company/jamitan-tech"}>
            <ImLinkedin fontSize={22}/>
            </SocialIcon>
            <SocialIcon href={contactUsData ? contactUsData.twitter : "https://www.instagram.com/jamitan_tech/"}>
              <FaXTwitter fontSize={22}/>
            </SocialIcon>
            <SocialIcon href={contactUsData ? contactUsData.instagram : "https://twitter.com/Jamitan_Tech"}>
              <AiFillInstagram fontSize={26}/>
            </SocialIcon>
          </SocialIcons>
        </div>
      </FooterContainer>
      </div>
    </>
  )
}

export default Footer