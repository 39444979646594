import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import ContactForm from '../Components/ContactElement';
import { HeroContainer2, HeroH1 } from '../Components/HeroElements';
import { Helmet } from 'react-helmet';


const Contact = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Contact Jamitan" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer2 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Contact Us</HeroH1>
        </HeroContainer2>
        <ContactForm />
        <Footer />
    </div>
  )
}

export default Contact