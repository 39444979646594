import React, {useState} from 'react'
import spatial from '../assets/images/hero.svg'
import { Button } from './ButtonElements'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, PartnerStrip, Image, ImageContainer } from './HeroElements'
import Esri from '../assets/images/esri.svg'
import Khatib from '../assets/images/khatib.svg'
import Aws from '../assets/images/aws.svg'
import Maxar from '../assets/images/maxar.svg'

const HeroSection = () => {
  const [hover, setHover] = useState(false)
  
  const onHover = () => {
    setHover(!hover)
  }


  return (
    <>
        <HeroContainer  id="home">
            <HeroBg style={{backgroundImage: `url(${spatial})`}}>
                <VideoBg  src={spatial} alt=' ' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Problem-Solving through <span style={{color:'#0492C2'}}>Geospatial</span><br /> Science and Technology</HeroH1>
                <HeroP>
                    We help build mapping and spatial analytical software, designed to support the<br /> actualization of government and business objectives around the globe
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="/contact" onMouseEnter={onHover} onMouseLeave={onHover} primary='true'>
                        Reach Out {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
        <PartnerStrip>
            <ImageContainer to='/partners/esri'>
                <Image src={Esri} alt='Esri' />
            </ImageContainer>
            <ImageContainer to='/partners/khatib'>    
                <Image src={Khatib} alt='Khatib' />
            </ImageContainer>
            <ImageContainer to='/partners/aws'>
                <Image src={Aws} alt='AWS' />
            </ImageContainer>
            <ImageContainer to='/partners/maxar' style={{marginRight: '0px'}}>
                <Image src={Maxar} alt='Maxar' />
            </ImageContainer>
        </PartnerStrip>
    </>
  )
}

export default HeroSection