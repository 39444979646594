import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu, } from './NavElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="/products">Products</SidebarLink>
                <SidebarLink to="/services">Services</SidebarLink>
                <SidebarLink to="/industries">Industries</SidebarLink>
                <SidebarLink to="https://blog.jamitan.com">Blog</SidebarLink>
                <SidebarLink to="https://blog.jamitan.com/gallery">Gallery</SidebarLink>
                <SidebarLink to="/about">About Us</SidebarLink>
                <SidebarLink to="/contact">Contact Us</SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar