import React, {useState} from 'react'
import { ContactFormContainer, FormField,MessageTextArea, SubmitButton, Div1, Form2, FormLabel, FormInput, Div2, Div3 } from './MessageUsElements'
import { PopupCard, CloseButton, Logo, PopupOverlay } from './popUpElements';
import logo from '../assets/images/jamitan.png'

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        company: '',
        phoneNumber: '',
        message: '',
      });
      const [isChecked, setIsChecked] = useState(false);
      const [submitMessage, setSubmitMessage] = useState('Submit')
      const [isChecked2, setIsChecked2] = useState(false);
      const [responseMessage, setResponseMessage] = useState(null);
      const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
      };
      const handleCheckboxChange2 = (event) => {
        setIsChecked2(event.target.checked);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitMessage('Submitting...')
        try {
          const response = await fetch('https://api.jamitan.com/enquiries.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                country: '',
                company: '',
                phoneNumber: '',
                message: '',
              });
              setIsChecked(false)
              setIsChecked2(false)
              setSubmitMessage('Submit')
             await response.json();
            setResponseMessage('Message sent successfully'); // Set success message
          } else {
            console.error('Error:', response.status);
            setResponseMessage('Message could not be sent. Please try again later'); // Set error message
          }
        } catch (error) {
          console.error('Error:', error);
          setResponseMessage('Message could not be sent. Please try again later'); // Set error message
        }
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleClosePopup = () => {
        setResponseMessage(null); // Hide the popup card
      };

    return (
        <div>
            <ContactFormContainer onSubmit={handleSubmit}>
                <Form2>
                    <Div1 style={{gap: '10px'}}>
                        <div style={{display: 'grid'}}>
                            <FormLabel>First Name<sup style={{color:'#FF1C1C'}}>*</sup></FormLabel> <br/>
                            <FormField type="text" style={{background:'#D9D9D9', padding:'16px 30px 16px 30px'}} id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
                        </div>
                        <div style={{display: 'grid'}}>
                            <FormLabel>Last Name<sup style={{color:'#FF1C1C'}}>*</sup></FormLabel><br/>
                            <FormField type="text" style={{background:'#D9D9D9', padding:'16px 30px 16px 30px'}} id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
                        </div>
                    </Div1>
                    <Div1 style={{gap: '10px'}}>
                        <div style={{display: 'grid'}}>
                            <FormLabel>Work Phone number<sup style={{color:'#FF1C1C'}}>*</sup></FormLabel><br/>
                            <FormField type="text" style={{background:'#D9D9D9', padding:'16px 30px 16px 30px'}} id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required  />
                        </div>
                        <div style={{display: 'grid'}}>
                            <FormLabel>Work Email address <sup style={{color:'#FF1C1C'}}>*</sup></FormLabel><br/>
                            <FormField type="text" style={{background:'#D9D9D9', padding:'16px 30px 16px 30px'}} id="email" name="email" value={formData.email} onChange={handleChange} required />
                        </div>
                    </Div1>
                    <Div1 style={{gap: '10px'}}>
                        <div style={{display: 'grid'}}>
                            <FormLabel>Company / Organization<sup style={{color:'#FF1C1C'}}>*</sup></FormLabel><br/>
                            <FormField type="text" style={{background:'#D9D9D9', padding:'16px 30px 16px 30px'}} id="company" name="company" value={formData.company} onChange={handleChange} required />
                        </div>
                        <div style={{display: 'grid'}}>
                            <FormLabel>Country / Region<sup style={{color:'#FF1C1C'}}>*</sup></FormLabel><br/>
                            <FormField type="text" style={{background:'#D9D9D9', padding:'16px 30px 16px 30px'}} id="country" name="country" value={formData.country} onChange={handleChange} required />
                        </div>
                    </Div1>
                    <Div1 style={{display:'block'}}>
                        <Div2>
                            <FormLabel>How can Jamitan Help ?<sup style={{color:'#FF1C1C'}}>*</sup></FormLabel><br/>
                            <MessageTextArea style={{background:'#D9D9D9', padding:'16px 30px 16px 30px'}} id="message" name="message" value={formData.message} onChange={handleChange} required />
                        </Div2>
                    </Div1>
                    <Div3>
                        <p style={{display:'flex'}}><FormInput type="checkbox" required checked={isChecked} onChange={handleCheckboxChange} style={{width: '14px', height:"14px", marginRight:"10px", borderColor:'#0492C2'}}/>By submitting this form, I authorize Jamitan to contact me to fulfill my request. I accept Jamitan's privacy policy.</p>
                        <p style={{display:'flex'}}><FormInput type="checkbox" required checked={isChecked2} onChange={handleCheckboxChange2} style={{width: '14px', height:"14px", marginRight:"10px", borderColor:'#0492C2'}}/>I authorize Jamitan and Jamitan partners to contact me with personalized communications about Jamitan's products, services, and events. I understand I may opt-out or manage my preferences at any time.</p>
                    </Div3>
                    <div>
                        <SubmitButton>{submitMessage}</SubmitButton>
                    </div>
                </Form2>
            </ContactFormContainer>
            {responseMessage && (
                <PopupOverlay>
                <PopupCard>
                    <Logo src={logo} alt="Logo" />
                    <p style={{padding:'20px'}}>{responseMessage}</p>
                    <CloseButton onClick={handleClosePopup}>X</CloseButton>
                </PopupCard>
                </PopupOverlay>
            )}
        </div>
    );
  }
export default ContactForm