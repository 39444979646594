import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer4, HeroH1 } from '../Components/HeroElements';
import {Image } from '../Components/ResusableElements';
import MaxarLogo from '../assets/images/maxar.png'
import { Helmet } from 'react-helmet';
import {FaLocationDot} from 'react-icons/fa6'
import { BsGlobe} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'


const Maxar = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Partners - Maxar</title>
        <meta name="description" content="Jamitan | Partners" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer4 style={{height:'249px', display:'grid', alignItems:'center', background:'#006e80'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Partners / Maxar</HeroH1>
        </HeroContainer4>
        <div style={{display:'flex', justifyContent:'center', margin:'50px'}}>
                <Image src={MaxarLogo} alt='Image10'  />
        </div>
        <div style={{textAlign:'center'}}>
            <h4>MAXAR TECHNOLOGIES</h4>
            <p>Colorado, United States</p>
        </div>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <h3>Overview</h3>
            <p style={{lineHeight:'1.5em'}}>Maxar is a leading provider of space technology and intelligence, which realizes the potential of space to assist businesses and governments in resolving issues on earth and beyond. With capabilities in Space Infrastructure and Earth Intelligence, Maxar collaborates with forward-thinking companies and more than 50 nations to monitor global change, provide broadband communications, and advance space operations.<br /><br/>
            Maxar solutions serve dozens of industries worldwide including Telecommunications, Mapping, Defense, Mobility and Logistics, Energy, Mining, Security, Satellite Communications, Intelligence amongst others. With over 4000 employees, Maxar collects more than 3.8 million km2 of high resolution imagery and has a total of 3.8 billion people interact with their technology every month. </p>
            <h3>Services</h3>
            <p style={{lineHeight:'1.5em'}}>Maxar offers geospatial services, satellite imagery and expert intelligence, robotics and servicing and also specializes in manufacturing, surveillance and communication, on-orbit servicing satellites, earth observation, radar, and satellite products.  </p>
            <h3>Contact Information</h3>
            <p>
            <p><span style={{marginRight:'5px'}}><FaLocationDot /></span>Location: 1300 W 120th Avenue Westminster, Colorado, USA.</p>
            <p><span style={{marginRight:'5px'}}><AiOutlineMail /></span>Email: <a href='mailto:info@maxar.com' target='_blank' rel="noreferrer">info@maxar.com</a></p>
            <p><span style={{marginRight:'5px'}}><BsGlobe /></span>Website: <a href='https://www.maxar.com' target='_blank' rel="noreferrer">www.maxar.com</a></p>
            </p>
        </div>
        <Footer />
    </div>
  )
}

export default Maxar