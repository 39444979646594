import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Fire from '../assets/images/fire.png'
import FireGis from '../assets/images/fireGis.png'
import { Helmet } from 'react-helmet';


const FireDept = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - Emergency Management | Fire</title>
        <meta name="description" content="Jamitan | Emergency Management Fire" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>Fire Department Accreditation</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%', paddingTop:'2%'}}>
            <p>Firefighters make critical decisions based on limited information every day. Agencies should strive to generate verified data for emergency and nonemergency needs to provide responders with the right data at the right time. Through the power of location intelligence, organizations can now adopt a smarter, more integrative approach to safety.</p>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Fire} alt='Image10' style={{width:'720px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>GIS supports accreditation</h2>
                    <p>Through the accreditation process, GIS can be used to analyze, measure, and share data with decision-makers. This data can come from computer-aided dispatch software, records management systems, community risk reduction activities, or any source deemed appropriate by the agency.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>GIS supports the self-assessment process</h2>
                    <p>The Center for Public Safety Excellence (CPSE) accreditation is "an all-hazard, quality improvement model based on risk analysis and self-assessment that promotes the establishment of community-adopted performance targets for fire and emergency service agencies." This model of continual improvement is heavily dependent on the collection and analysis of data, and GIS supports self-assessment. The benefits of using the appropriate GIS data to measure standards of cover performance and focus resources are far-reaching.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={FireGis} alt='Image11' style={{width:'720px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default FireDept