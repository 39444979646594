import React, {useState} from 'react'
import Navbar from '../Components/Nav'
import Sidebar from '../Components/MobileNav';
import Footer from '../Components/Footer';
import { HeroContainer3, HeroH1 } from '../Components/HeroElements';
import { BlueContainer, FlexDiv, FlexDiv2, Image } from '../Components/ResusableElements';
import Realtime from '../assets/images/Realtime.png'
import Command from '../assets/images/Command.png'
import Incidents from '../assets/images/Incidents.png'
import Intelligence from '../assets/images/Intelligence.png'
import { Helmet } from 'react-helmet';


const NatSec = () => {
    const [isOpen, setIsOpen] = useState(false); 

    const toggle = () => {
        setIsOpen(!isOpen);
    };
  return (
    <div>
    <Helmet>
        <title>Industry - National Security</title>
        <meta name="description" content="Jamitan | Architecture, Engineering, and Construction" />
      </Helmet>
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <HeroContainer3 style={{height:'249px', display:'grid', alignItems:'center'}}>
                <HeroH1 style={{color: '#fff', textDecoration:'underline', lineHeight:''}}>National Security</HeroH1>
        </HeroContainer3>
        <div style={{padding: '8%', paddingBottom:'4%'}} className='special-text'>
            <p>Managing and operationalizing critical information involves aligning software tools with your organization's people, mission, and data. Focusing on the power of location to identify threats and manage risk can help national security ensure operational readiness.  Leverage geographical information system (GIS) technology as the tested and proven platform that can streamline the complex business requirements and workflows of security operations. Collect, understand, operationalize and share information that informs decision making and protects your most critical assets.
                Our solutions for security operations provide business leaders, security managers, and security teams with real-time situational awareness, command and control capabilities, and advanced analytics.
            </p>
        </div>
        <div style={{textAlign:'center', paddingBottom:'10px'}}>
            <h1>Security operations strategies</h1>
        </div>
        <FlexDiv>
            <BlueContainer>
                <Image src={Intelligence} alt='Image10' style={{width:'500px'}}  />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Intelligence and Analysis</h2>
                    <p>Geospatial technology provides vital tools that embrace the intelligence life cycle, supporting threat intelligence workflows and investigative link analysis. Fuse raw data from multiple sources in real time, detect networks and relationships, and share tradecraft. Whether you are an intelligence analyst, technologist, or operator, you can easily organize complex threat data into a visualization that is easy to interpret, understand, and share to support threat mitigation and investigations. </p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Incidents and Events</h2>
                    <p>Natural disasters, active assailants, political unrest, crime, and terrorism reflect the heightened need for coordinated preventive and response efforts as well as critical incident management. Location intelligence enhances your ability to protect employees, customers, organizational assets, and the public. Protecting critical infrastructure and special events demands planning, collaboration, and access to information. A geographic information system (GIS) unifies security teams, supports the early indication of risk, and aids rapid response and incident closure.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Incidents} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <FlexDiv>
            <BlueContainer>
                <Image src={Command} alt='Image12' style={{width:'500px'}} />
            </BlueContainer>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Command and Control</h2>
                    <p>Observe, orient, decide, and act Leverage a command-and-control system that enables collecting and analyzing data, managing resources, sharing information, monitoring situations, and executing a plan. A geographic information system (GIS) improves command and control with the unique ability to manage, communicate, and operationalize data in a swift and agile manner—directly supporting diverse, shifting missions. Streamline security planning with timely information and decision-making, authority and direction over field resources, and real-time executive briefings and after-action assessments.</p>
                </div>
            </BlueContainer>
        </FlexDiv>
        <FlexDiv2>
            <BlueContainer>
                <div style={{padding:'8%'}}>
                    <h2>Real-time Threat Monitoring </h2>
                    <p>Location intelligence delivers complete situational awareness and boosts an operations center's capabilities, making it the brain center of any organization. With real-time data feeds, operations centers can best facilitate rapid incident response, minimize impact, and enable early detection. Filter volumes of disparate types of data to understand threat intelligence and conduct incident triage. Geographic information system (GIS) technology provides threat monitoring and reporting tools that allow your security team to aggregate and share results, facilitate mitigation, manage consequences, and support investigations.</p>
                </div>
            </BlueContainer>
            <BlueContainer>
                <Image src={Realtime} alt='Image11' style={{width:'500px'}} />
            </BlueContainer>
        </FlexDiv2>
        <Footer />
    </div>
  )
}

export default NatSec