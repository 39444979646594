import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import {FaTimes} from 'react-icons/fa';
import {PiCaretDownBold} from 'react-icons/pi'

export const Nav = styled.nav`
  background:  #fff;
  width: 100%;
  margin-top: 10px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  position: sticky;
  top: 0;
  font-family: Inter;
  z-index: 100;
  padding-bottom: 10px;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  height: 80px;
  z-height: 1;
  width: 100%;
  padding: 0 24px;
  padding-top: 20px;
  align-items: center; /* Center vertically */
`;

export const Logo = styled(Link)`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-size: 1.5rem;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 810px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-left: 15%; /* Push NavMenu to the right */

  @media screen and (max-width: 810px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  position: relative; /* Required for dropdown */

  &:hover {
    ul {
      display: block; /* Show dropdown on hover */
    }
  }
`

export const NavLinks = styled(Link)`
    color: #1E1E1E;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 90%;
    cursor: pointer;
    position: relative; /* Required for the arrow */
    &:hover {
    border-bottom: 3px solid #c1ad77;
  };
    &:active {
        border-bottom: 3px solid #0492C2;
    }
`
export const NavLinks2 = styled(Link)`
    color: #1E1E1E;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
    position: relative; /* Required for the arrow */
    
    &:active {
        border-bottom: 3px solid #0492C2;
    }
`
export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        display:none;
    }
`
export const DropdownMenu = styled.ul`
  list-style: none;
  position: absolute;
  top: 100%; /* Position below the parent item */
  left: 0;
  background: #fff;
  display: none; /* Hidden by default */
  width: 200px;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;

  &:hover {
    display: block; /* Show dropdown on hover */
  }
`;

export const DropdownItem = styled.a`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #f0f0f0;
    .sub-menu-arrow {
    transform:  rotate(180deg);
    transition: transform 0.2s ease-in-out;
    font-size: 16px;
    margin-right: 3px;
  }
  }
`;

export const NavBtnLink = styled(Link)`
    border-radius: 50px;
    background: #A4CA4F;
    white-space: nowrap;
    padding: 10px 22px;
    color: #606062;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all .2s ease-in-out;
        background: #fff;
        color: #606062;
    }
`

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100%;
    background: #0492C2;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition:  0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')}; 
`

export const CloseIcon = styled(FaTimes)`
    color: #fff;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    color:#fff;
`

export const SidebarLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #A4CA4F;
        transition: 0.2s ease-in-out;
    }
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;
    margin-left: -20px;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`

export const SidebarRoute = styled(Link)`
    border-radius: 50px;
    background: #A4CA4F;
    white-space: nowrap;
    padding: 16px 64px;
    color: #606062;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer:
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #606062;
    }
`

const Submenu = ({ sublinks }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
    const LowercaseLink = (sublinks.label).toLowerCase(); 
  
    return (
      <>
        <DropdownItem isOpen={isOpen} onClick={toggleDropdown}>
            <NavLinks2 to={`/${LowercaseLink}`}>{sublinks.label}</NavLinks2>
        <span className="sub-menu-arrow"><PiCaretDownBold/></span>
      </DropdownItem>
      <DropdownMenu isOpen={isOpen}>
        {sublinks.links.map((link, index) => (
          <DropdownItem key={index} href={link.url}>
            <Link style={{textDecoration:'none', color:'black', textAlign:'start', paddingLeft:'10px'}} to={link.url}>{link.label}</Link>
          </DropdownItem>
        ))}
      </DropdownMenu>
      </>
    );
  };
  
  // Example usage:
  /*
  <NavMenu>
    <NavItem>
      <NavLinks to="/home">Home</NavLinks>
    </NavItem>
    <NavItem>
      <Submenu
        sublinks={{
          label: 'Services',
          links: ['Sublink 1', 'Sublink 2'],
        }}
      />
    </NavItem>
    // Add more NavItems and Submenu components as needed
  </NavMenu>
  */
  
  export default Submenu;